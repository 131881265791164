import {VERTICES} from "../../Global/variables";
let threshold =0.17;
export function vertexSnap(prevVertexSnap, point) {
    if (prevVertexSnap && prevVertexSnap.position.distanceTo(point) <= threshold) {
        return prevVertexSnap
    } else {
        let found = false;
        let vertices = VERTICES;
        for (let i = 0; i < vertices.length && !found; i++) {
            for (let j = 0; j < vertices[i].length && !found; j++) {
                const d = vertices[i][j].position.distanceTo(point);
                if (d <= 0.17) {
                    found = true;
                    return vertices[i][j];
                }
            }
        }
        if (!found) {
            if (prevVertexSnap) {
                prevVertexSnap.material.opacity = 0;
            }
            return null;
        }
    }
}
