import { atom, selector } from 'recoil';

// Define the atom for POLYGONS array
export const polygonsState = atom({
    key: 'polygonsState',
    default: []
});

export const magicToggleState = atom({
    key: 'magicToggleState',
    default: false
});
export const isSetBackAdded = atom({
    key: 'isSetBackAdded',
    default: false
});
export const editSetBacks = atom({
    key: 'editSetBacks',
    default: false
});

export const setBackInput = atom({
    key: 'setBackInput',
    default: {}
});
export const avaArea = atom({
    key: 'avaArea',
    default: ''
});
export const deleteSetBacks = atom({
    key: 'deleteSetBacks',
    default: false
});
export const selectedIndicesState = atom({
    key: 'selectedIndicesState',
    default: { segmentIndex: null, obstacleIndex: null }
});

export const deleteSegmentIndex = atom({
    key: 'deleteSegmentIndex',
    default: null
});

export const deleteObstacleIndex = atom({
    key: 'deleteObstacleIndex',
    default: null
});

export const selectedObstacleHeight = atom ({
    key: 'selectedObstacleHeight',
    default:0,
});

export const addPanels = atom({
    key: 'addPanels',
    default: false
});

export const solarPanel = atom({
    key: 'solarPanel',
    default: []
});
export const selectedPanel = atom({
    key: 'selectedPanel',
    default: null
});
export const polygonDetailsSelector = selector({
    key: 'polygonDetailsSelector',
    get: ({ get }) => {
        const selectedIndices = get(selectedIndicesState);
        const polygons = get(polygonsState);
        return selectedIndices.segmentIndex !== null ? polygons[selectedIndices.segmentIndex].details : null;
    }
});

