import React, { useEffect, useState } from "react";
import LoaderLogo from "../assets/loader-logo.svg";
import { Flex, Image, Typography } from "antd";
import { Link } from "react-router-dom";
import BubbleLoader from "./BubbleLoader";

let data = [
  {
    type: 0,
    title: "Verifying information...",
  },
  {
    type: 1,
    title: "Getting the model info...",
  },
  {
    type: 2,
    title: "Getting the model ready...",
  },
  {
    type: 3,
    title: "Stay on the screen...",
  },
  {
    type: 4,
    title: "Patience is the key to success...",
  },
  {
    type: 5,
    title: "We are almost there...",
  },
  {
    type: 6,
    title: "Redirecting to designer tool...",
  },
  {
    type: 7,
    title: "Generating Dxf...",
  },
];

let interval = null;
const LoadingScreen = ({ informationChecked,type }) => {
  const [currentTextIndex, setCurrentTextIndex] = useState(0);

  useEffect(() => {
    const finalType = type !== undefined ? setCurrentTextIndex(7) : informationChecked;
    if (finalType != null) {
      setCurrentTextIndex((prevIndex) => (prevIndex + 1) % data.length);
      const interval = setInterval(() => {
        if(currentTextIndex === 6){
          closeInterval();
        } else {
          setCurrentTextIndex((prevIndex) => (prevIndex + 1) % data.length);
        }
      }, 4000);
      return () => closeInterval();
    }
  }, [informationChecked]);

  const closeInterval = () => {
    clearInterval(interval)
  }

  return (
    <Flex justify="center" align="center" className="h-100" vertical>
      <Image
        preview={false}
        width={110}s
        src={LoaderLogo}
        alt="loader-logo"
        style={{ marginBottom: "20px" }}
      />
      {data[currentTextIndex] && (
        <Typography.Title level={2} style={{ marginBottom: "30px" }}>
          {data[currentTextIndex].title}
        </Typography.Title>
      )}
      <BubbleLoader />
      <Link to={"/"} className="fw-500" style={{ marginTop: "20px" }}>
        Back to Eagle Eye
      </Link>
      <Typography.Text type="secondary" strong style={{ marginTop: "40px" }}>
        © THE EAGLE EYE TEAM
      </Typography.Text>
      <Typography.Text
        type="secondary"
        strong
        style={{ marginTop: "10px", maxWidth: "585px", textAlign: "center" }}
      >
        Eagle Eye creates high-end roofing and solar software tools that aim to
        manage projects easier than before.
      </Typography.Text>
    </Flex>
  );
};

export default LoadingScreen;
