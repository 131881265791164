import {POLYGONS} from "../../Global/variables";
import {createEdgesWireframe} from "../Drawing";
import {sceneManager} from "../../SetUps/SceneManager";
import {MATERIALS} from "../../Global/materials";
import * as THREE from "three";
import {createSegmentPlane} from "../../Global/functions";
import {removeMesh} from "./Functions";
import {updateSetBackPoints} from "./CenterMethod";

// Function to add setback to a specific edge
export function addSetbackToEdge(setbacks, edgeIndex, inputValue, segmentIndex) {
    let plane = createSegmentPlane(segmentIndex);
    // let mesh =-POLYGONS[segmentIndex].shape;

    const edges  = setbacks[edgeIndex];
    const point1 = edges.point1;
    const point2 = edges.point2;
    const prevValue = edges.value *  0.0254;
    let setbackValue = (inputValue *  0.0254) - prevValue;
    // const prevVertex1 = point1.clone();
    // const prevVertex2 = point2.clone();

    const normal = calculateNormal(point1, point2);
    const inwardNormal = ensureInwardNormal(setbacks, edgeIndex, normal);
    let newVertex1 = new THREE.Vector3().addVectors(point1, inwardNormal.clone().multiplyScalar(setbackValue));
    let newVertex2 = new THREE.Vector3().addVectors(point2, inwardNormal.clone().multiplyScalar(setbackValue));

    edges.point1 =  newVertex1;
    edges.point2 =  newVertex2;



    adjustConnectedEdges(setbacks, edgeIndex, newVertex1, newVertex2,  edges);
    // edges.point1 =  newVertex1;
    // edges.point2 =  newVertex2;

    //----------------->  end <------------------------
    // updateSetBackPoints(setbacks, edges);
    updateWireframe(setbacks,segmentIndex);
}


function adjustConnectedEdges(setbacks, edgeIndex, newVertex1, newVertex2,edges) {
    const prevEdgeIndex = (edgeIndex - 1 + setbacks.length) % setbacks.length;
    const nextEdgeIndex = (edgeIndex + 1) % setbacks.length;

    const prevEdge = setbacks[prevEdgeIndex];
    const nextEdge = setbacks[nextEdgeIndex];

    if (prevEdge) {
        if (prevEdge.vertexId2 === edges.vertexId1 ) {
            let nearestPoint = getNearestPointOnLineSegment(prevEdge.point1, prevEdge.point2, newVertex1);
            newVertex1.copy(nearestPoint);
            prevEdge.point2 = newVertex1;
        }
         else if (prevEdge.vertexId1 === edges.vertexId2 ) {
            let nearestPoint = getNearestPointOnLineSegment(prevEdge.point1, prevEdge.point2, newVertex2);
            newVertex2.copy(nearestPoint);
            prevEdge.point1 = newVertex2;
        }
        else if (prevEdge.vertexId1 === edges.vertexId1) {
            let nearestPoint = getNearestPointOnLineSegment(prevEdge.point1, prevEdge.point2, newVertex2);
            newVertex2.copy(nearestPoint);
            prevEdge.point1 = newVertex2;
        }
        else if (prevEdge.vertexId2 === edges.vertexId2 ) {
            let nearestPoint = getNearestPointOnLineSegment(prevEdge.point1, prevEdge.point2, newVertex2);
            newVertex2.copy(nearestPoint);
            prevEdge.point2 = newVertex2;
        }
    }

    if (nextEdge) {
        if (nextEdge.vertexId1 === edges.vertexId2 ) {
            let nearestPoint = getNearestPointOnLineSegment(nextEdge.point1, nextEdge.point2, newVertex2);
            newVertex2.copy(nearestPoint);
            nextEdge.point1 = newVertex2;
        }
        else if (nextEdge.vertexId2 === edges.vertexId1 ) {
            let nearestPoint = getNearestPointOnLineSegment(nextEdge.point1, nextEdge.point2, newVertex1);
            newVertex1.copy(nearestPoint);
            nextEdge.point2 = newVertex1;
        }
        else if (nextEdge.vertexId1 === edges.vertexId1 ) {
            let nearestPoint = getNearestPointOnLineSegment(nextEdge.point1, nextEdge.point2, newVertex1);
            newVertex1.copy(nearestPoint);
            nextEdge.point21 = newVertex1;
        }
        else if (nextEdge.vertexId2 === edges.vertexId2 ) {
            let nearestPoint = getNearestPointOnLineSegment(nextEdge.point1, nextEdge.point2, newVertex1);
            newVertex1.copy(nearestPoint);
            nextEdge.point2 = newVertex1;
        }
    }
}

function calculateNormal(v1, v2) {
    const edge = new THREE.Vector3().subVectors(v2, v1);
    return new THREE.Vector3(-edge.y, edge.x, 0).normalize();
}

function ensureInwardNormal(setbacks, edgeIndex, normal) {
    const edges = setbacks[edgeIndex];
    const point1 = edges.point1;
    const point2 = edges.point2;

    // Calculate the midpoint of the edge
    const midpoint = new THREE.Vector3().addVectors(point1, point2).divideScalar(2);
    // Calculate the signed area (or use centroid method)
    const signedArea = calculateSignedArea(setbacks);
    const toMidpoint = signedArea < 0 ? normal : normal.negate();

    return toMidpoint;
}

function calculateSignedArea(edges) {
    let area = 0;
    for (let i = 0; i < edges.length; i++) {
        const point1 = edges[i].point1;
        const point2 = edges[i].point2;
        area += (point2.x - point1.x) * (point2.y + point1.y);
    }
    return area;
}

function getNearestPointOnLineSegment(lineStart, lineEnd, point) {
    const line = new THREE.Vector3().subVectors(lineEnd, lineStart);
    const t = (new THREE.Vector3().subVectors(point, lineStart)).dot(line) / line.lengthSq();
    const clampedT = Math.max(0, Math.min(1, t));
    return new THREE.Vector3().addVectors(lineStart, line.multiplyScalar(clampedT));
}

function updateWireframe(edges,index) {
    let edgePoints = [];
    for (let i = 0; i < edges.length; i++) {
        edgePoints.push(edges[i].point1);
        edgePoints.push(edges[i].point2);
    }
    let material = MATERIALS.setbackWireframe.clone();
    let edgeWireframe = createEdgesWireframe(edgePoints, material);

    let setback = POLYGONS[index].setBacks;
    if(setback.mesh){
        removeMesh(setback);
    }
   setback.mesh =edgeWireframe;
   sceneManager.scene.add(setback.mesh);
   setback.mesh.geometry.attributes.position.needsUpdate = true;

}





