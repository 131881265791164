import React, { useEffect, useState } from 'react'
import { Flex, Image, Layout, Skeleton, Typography } from 'antd'
import { useParams } from 'react-router'
import { handleErrors } from '../helpers/CONSTANT'
import PhotoMetaData from './PhotoMetaData'
import { getProjectImages } from '../helpers/API'

const PhotoList = ({ activeKey }) => {
  const params = useParams()
  const [imageList, setImageList] = useState([])
  const [loadingImages, setLoadingImages] = useState(false)
  const [openMetaModal, setOpenMetaModal] = useState(false)
  const [metaData, setMetaData] = useState(null)
  const projectId = params?.designID
  const token = params?.token

  useEffect(() => {
    fetchDesignImages()
  }, [projectId, token])

  const fetchDesignImages = async () => {
    try {
      setLoadingImages(true)
      const response = await getProjectImages(token, projectId)
      setImageList(response.data.data)
      setLoadingImages(false)
    } catch (error) {
      handleErrors(error)
      setLoadingImages(false)
    } finally {
      setLoadingImages(false)
    }
  }

  const onCloseMetaModal = () => {
    setOpenMetaModal(false)
  }

  const openDrawerMetaModal = async (item) => {
    setMetaData(item)
    setOpenMetaModal(true)
  }

  return (
    <Flex vertical gap={'small'}>
      {imageList?.length > 0 && (
        <Layout.Header style={{ padding: 0, height: '30px', marginBottom: '5px' }}>
          <Typography.Text strong style={{ fontSize: '18px' }}>{`Total Images - (${imageList?.length})`}</Typography.Text>
        </Layout.Header>
      )}
      {loadingImages ? (
        <Skeleton active />
      ) : (
        <Flex gap={10} flex={'100'} wrap="wrap">
          {imageList?.length > 0
            ? imageList?.map((photo, index) => (
                <div  className='image__list'>
                  <div className="image__name">{photo?.name}</div>
                  <Image
                    onClick={() => openDrawerMetaModal(photo)}
                    preview={false}
                    width={100}
                    height={90}
                    key={index}
                    style={{
                      borderRadius: '5px',
                      cursor: 'pointer',
                      objectFit: 'cover',
                    }}
                    src={photo.thumbnail} // Assuming your photo object has a 'url' property
                    alt={`Photo ${index + 1}`}
                  />
                </div>
              ))
            : 'No Images found'}
          {openMetaModal && <PhotoMetaData metaData={metaData} onClose={onCloseMetaModal} openMetaModal={openMetaModal} />}
        </Flex>
      )}
    </Flex>
  )
}

export default PhotoList
