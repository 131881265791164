import {DrawingGlobals} from "../../Global/variables";
import {LINES} from "../../Global/materials";
import * as THREE from "three";

export function refLineSnap(prevRefPoint, mousePosition) {
    if (prevRefPoint != null && mousePosition.distanceTo(prevRefPoint) <= 0.1) {
        return prevRefPoint
    } else {
        let a = DrawingGlobals.intersectionPoints[0];
        let b = DrawingGlobals.intersectionPoints[1];

        let rightAnglePoint = findRightAnglePoint(a, b, mousePosition)
        let collinearPoint = findCollinearPoint(a, b, mousePosition)

        if (mousePosition.distanceTo(rightAnglePoint) <= 0.1) {
            return rightAnglePoint;
        } else if (mousePosition.distanceTo(collinearPoint) <= 0.1) {
            return collinearPoint;
        } else {
            LINES.referenceLine.visible = false;
            return null;
        }
    }
}

function findRightAnglePoint(a, b, mousePosition) {
    let abDirection = new THREE.Vector3().copy(b).sub(a);
    abDirection.normalize();

    let mouseDirection = new THREE.Vector3().copy(mousePosition).sub(a);
    mouseDirection.normalize();
    let distanceToMouse = a.distanceTo(mousePosition);
    let projectedLength = mouseDirection.dot(abDirection);
    let scaledABDirection = abDirection.clone().multiplyScalar(projectedLength);

    let perpendicularVector = new THREE.Vector3().copy(mouseDirection).sub(scaledABDirection);
    return new THREE.Vector3().copy(a).add(perpendicularVector.normalize().multiplyScalar(distanceToMouse));
}

function findCollinearPoint(a, b, mousePosition) {
    let distance = a.distanceTo(mousePosition);
    let abVector = new THREE.Vector3().copy(b).sub(a);
    let direction = abVector.clone().normalize();
    let directionToMouse = new THREE.Vector3().copy(mousePosition).sub(a).normalize();
    let dotProduct = direction.dot(directionToMouse);
    if (dotProduct < 0) {
        direction.negate();
    }
    let scaledDirection = direction.clone().multiplyScalar(distance);
    let collinearPoint = new THREE.Vector3().copy(a).add(scaledDirection);
    collinearPoint.z = mousePosition.z;
    return collinearPoint;
}

export function showReferenceLine(point) {
    const intersectionPoint = DrawingGlobals.intersectionPoints[0];
    const positionArray = LINES.referenceLine.geometry.attributes.position.array;

    positionArray[0] = intersectionPoint.x;
    positionArray[1] = intersectionPoint.y;
    positionArray[2] = intersectionPoint.z;
    positionArray[3] = point.x;
    positionArray[4] = point.y;
    positionArray[5] = point.z;

    LINES.referenceLine.visible = true;
    LINES.referenceLine.computeLineDistances();
    LINES.referenceLine.geometry.attributes.position.needsUpdate = true;
}