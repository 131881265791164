import * as THREE from 'three'
import proj4 from "proj4";
import {VARS} from "../Global/variables";
import {DEV_MOD} from "../../helpers/CONSTANT";
import {sceneManager} from "../SetUps/SceneManager";

export function calculatePitch(polygon){
    const plane1 = new THREE.Plane(new THREE.Vector3(0,0,1)); // x-y plane
    const plane2 = new THREE.Plane(); // segment plane

    // highest and lowest points
    let maxZ = polygon[0].point1.clone();
    let minZ = polygon[0].point1.clone();
    for (let i=1; i < polygon.length; i++) {
        if (polygon[i].point1.z > maxZ.z) {
            maxZ = polygon[i].point1.clone();
        }
        if (polygon[i].point1.z < minZ.z) {
            minZ = polygon[i].point1.clone();
        }
    }

    // 3rd point to make plane must not lie in line with min and max points
    const restPoints = polygon.filter((point) => !point.point1.equals(maxZ) && !point.point1.equals(minZ));
    const minToMax = new THREE.Vector3().subVectors(minZ, maxZ);
    let point = 0;
    let angle = minToMax.angleTo(new THREE.Vector3().subVectors(restPoints[0].point1, maxZ));
    for (let i = 1; i < restPoints.length; i++) {
        let newAngle = minToMax.angleTo(new THREE.Vector3().subVectors(restPoints[i].point1, maxZ));
        if (newAngle > angle) {
            angle = newAngle;
            point = i;
        }
    }
    plane2.setFromCoplanarPoints(maxZ, minZ, restPoints[point].point1);

    const n1 = plane1.normal;
    const n2 = plane2.normal;
    const dir = new THREE.Vector3().crossVectors(n1, n2); // intersection of two planes
    if (dir.length() === 0) {
        return 0;
    }
    const run = new THREE.Vector3().crossVectors(n1, dir);
    const hyp = new THREE.Vector3().crossVectors(n2, dir);

    let pitch = radians_to_degrees(hyp.angleTo(run)).toFixed(0);
    if (pitch > 90) {
        pitch = 180 - pitch;
    }

    const v = new THREE.Vector3().addVectors(minZ, dir.multiplyScalar(2));
    const line = new THREE.Line3(minZ, v);
    const v2 = new THREE.Vector3();
    line.closestPointToPoint(maxZ, false, v2);

    return {pitch_angle: pitch, p1: maxZ.clone(), p2: v2.clone()};

}

export function radians_to_degrees(rad) {
    return (rad * 180.0) / Math.PI;
}

export function getPolyArea(polygon) {

    let poly = [];
    for (let i = 0; i < polygon.length; i++) {
        poly.push([polygon[i].point1.x, polygon[i].point1.y, polygon[i].point1.z]);
    }
    if (poly.length < 3) {

        return 0;
    } else {
        let total = [0, 0, 0]
        for (let i = 0; i < poly.length; i++) {
            var vi1 = poly[i];
            if (i === poly.length - 1) {
                var vi2 = poly[0];
            } else {
                var vi2 = poly[i + 1];
            }
            let prod = cross(vi1, vi2);
            total[0] = total[0] + prod[0];
            total[1] = total[1] + prod[1];
            total[2] = total[2] + prod[2];
        }
        let result = dot(total, unit_normal(poly[0], poly[1], poly[2]));

        return Math.abs(result / 2) * 10.7639;
    }
}

function dot(a, b) {
    return a[0] * b[0] + a[1] * b[1] + a[2] * b[2];
}

// cross product of vectors a and b
function cross(a, b) {
    let x = (a[1] * b[2]) - (a[2] * b[1]);
    let y = (a[2] * b[0]) - (a[0] * b[2]);
    let z = (a[0] * b[1]) - (a[1] * b[0]);
    return [x, y, z];
}

function unit_normal(a, b, c) {
    let x = det([
        [1, a[1], a[2]],
        [1, b[1], b[2]],
        [1, c[1], c[2]]
    ]);
    let y = det([
        [a[0], 1, a[2]],
        [b[0], 1, b[2]],
        [c[0], 1, c[2]]
    ]);
    let z = det([
        [a[0], a[1], 1],
        [b[0], b[1], 1],
        [c[0], c[1], 1]
    ]);
    let magnitude = Math.pow(Math.pow(x, 2) + Math.pow(y, 2) + Math.pow(z, 2), 0.5);
    return [x / magnitude, y / magnitude, z / magnitude];
}

function det(a) {
    return a[0][0] * a[1][1] * a[2][2] + a[0][1] * a[1][2] * a[2][0] + a[0][2] * a[1][0] * a[2][1] - a[0][2] * a[1][1] * a[2][0] - a[0][1] * a[1][0] * a[2][2] - a[0][0] * a[1][2] * a[2][1];
}

export function getAzimuth(P1, P2) {

    let {originXY, proj} = getModelData();

    let lonlat1 = proj.inverse([originXY[0] - P1.x, originXY[1] - P1.y]);
    let lonlat2 = proj.inverse([originXY[0] - P2.x, originXY[1] - P2.y]);
    let lat1 = degrees_to_radians(lonlat1[1]);
    let lat2 = degrees_to_radians(lonlat2[1]);
    let lon1 = degrees_to_radians(lonlat1[0]);
    let lon2 = degrees_to_radians(lonlat2[0]);
    let deltalon = lon2 - lon1;
    let y2 = Math.sin(deltalon) * Math.cos(lat2);
    let x2 = (Math.cos(lat1) * Math.sin(lat2)) - (Math.sin(lat1) * Math.cos(lat2) * Math.cos(deltalon));
    let azimth = radians_to_degrees(Math.atan2(y2, x2));
    if (azimth < 0) {
        azimth = 360 + azimth;
    }
    return (azimth);
}

function getModelData() {
    let projection = null;
    let originXY = null;
    if(DEV_MOD){
        //fionia modal cords
        originXY = [681184, 4585677]
        projection= "+proj=utm +zone=19 +datum=WGS84 +units=m +no_defs +type=crs"
    }
    else{
        let coordinates = VARS.Current_Project?.co_ordinates.split(" ");
        let x = parseInt(coordinates[0]);
        let y = parseInt(coordinates[1]);
        originXY = [x, y]
        projection = VARS.Current_Project.projection
    }

    let proj = proj4(projection)
    return {originXY, proj};
}

function degrees_to_radians(degrees) {
    var pi = Math.PI;
    return degrees * (pi / 180);
}


