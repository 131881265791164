import { VARS} from "../Global/variables";
import SegmentManager from "../Segments/SegmentManager";
import ObstacleManager from "../Obstacles/ObstacleManager";
import {COLORS} from "../Global/materials";
import {sceneManager} from "../SetUps/SceneManager";
export function loadDrawings(scene,updateSegmentList,updateObstacleList){

    if(VARS.Current_Project?.model_data && Object.keys(VARS.Current_Project.model_data).length !== 0){
        let data = JSON.parse(VARS.Current_Project.model_data)

        for (let i = 0; i < data.length; i++){
            let coordinates= data[i].polygon;
            let segment = new SegmentManager(scene, null,updateSegmentList);
            segment.addShape(coordinates);
            if (data[i].obstruction && data[i].obstruction.length > 0) {
                data[i].obstruction.forEach((obs) => {
                    let obstacle = new ObstacleManager(scene, null, null,null, updateObstacleList,i)

                    if (obs.type === "rectangle") {
                        obstacle.completeShape(obs.polygon, obs.height, COLORS.maroon, "rectangle")
                    }
                    if (obs.type === "circle") {
                        obstacle.completeShape(obs.polygon, obs.height, COLORS.orange, "circle")
                    }
                    obstacle = null;
                })
            }
            segment=null;
        }
    }
}