import React, { useEffect, useRef, useState} from "react";
import {DesignerLayout} from "../layout/DesignerLayout";
import Segment from "../components/Segment";
import Main from "../ThreeJs/Main";
import LoadingScreen from "../components/LoadingScreen";
import {useParams} from "react-router-dom";
import {checkUser, getDesign, updateProjectData} from "../helpers/API";
import NotFound from "../components/errorsscreens/NotFound";
import {DEV_MOD, handleErrors} from "../helpers/CONSTANT";
import {DrawingModes, POLYGONS} from "../ThreeJs/Global/variables";
import DeleteConfirmation from "../components/DeleteConfirmation";
import { transformPolygonData} from "../ThreeJs/DataUtils/SavaData";
import {generateDxf} from "../ThreeJs/Files/Dxf/DxfGenerator";
import {useRecoilState, useSetRecoilState} from "recoil";
import {deleteObstacleIndex, deleteSegmentIndex, selectedIndicesState} from "../ThreeJs/ReactStates/states";
import {deleteObstacle} from "../ThreeJs/Obstacles/ObstacleFunctions";
import {deleteSingleSegment} from "../ThreeJs/Segments/Delete";
import { deleteObstacleSelector, deleteSegmentSelector, updatePolygonDetailsSelector} from "../ThreeJs/ReactStates/updates";
import {removeSegmentDragControls, setSegmentDragControls} from "../ThreeJs/Segments/DragControls";
import {removeObstacleDragControls, setObstacleDragControls} from "../ThreeJs/Obstacles/DragControls";

const Designer = () => {

    const [currentDrawingMode, setCurrentDrawingMode] = useState(DrawingModes.NONE);
    const currentDrawingModeRef = useRef(DrawingModes.NONE);
    const [obstacleType, setObstacleType] = useState(null);
    const obstacleTypeRef = useRef(null);
    const [deleteConfirmationVisible, setDeleteConfirmationVisible] = useState(false);
    const [deleteObsIndex,setDeleteObsIndex] = useRecoilState(deleteObstacleIndex);
    const [deleteSegIndex,setDeleteSegIndex] = useRecoilState(deleteSegmentIndex);
    const [selectedIndices, setSelectedIndices] = useRecoilState(selectedIndicesState);
    const updateObstacleList = useSetRecoilState(deleteObstacleSelector);
    const updateSegmentList = useSetRecoilState(deleteSegmentSelector)
    const setPolygonDetails = useSetRecoilState(updatePolygonDetailsSelector);

    const [isLoading, setIsLoading] = useState(false);
    const [informationChecked, setInformationChecked] = useState(null);
    const [currentUser, setCurrentUser] = useState(null);
    const [modelProgress, setModelProgress] = useState(0);
    const [currentDesign, setCurrentDesign] = useState(null);
    const {token, designID} = useParams();

    const updateDrawingMode = (newMode) => {
        setCurrentDrawingMode(newMode);
        currentDrawingModeRef.current = newMode;
    };

    const updateObstacleType = (type) => {
        setObstacleType(type);
        obstacleTypeRef.current = type
    }

    const handleDeleteCancellation = () => {
        setDeleteConfirmationVisible(false);
        setDeleteSegIndex(null);
        setDeleteObsIndex(null);
    };

    const handleDeleteConfirmation = () => {

        let segmentIndex = selectedIndices.segmentIndex;
        let obsIndex = selectedIndices.obstacleIndex;

       if(deleteObsIndex != null){
           deleteObstacle(segmentIndex,deleteObsIndex)
           updateObstacleList(deleteObsIndex);
           if(deleteObsIndex === obsIndex){
               updateSelection(segmentIndex, null)
               removeObstacleDragControls();
           }
           if(deleteObsIndex < obsIndex){
               updateSelection(segmentIndex, obsIndex-1)
               let selectedObs = POLYGONS[segmentIndex].obstructions[obsIndex-1].mesh;
               setObstacleDragControls(selectedObs)
               // selectObstacle(selectedIndices,obsIndex-1, setSelectedIndices)
           }
           setDeleteObsIndex(null);
       }
       else if(deleteSegIndex != null){
           deleteSingleSegment(deleteSegIndex,segmentIndex)
           updateSegmentList(deleteSegIndex)
           if(segmentIndex === deleteSegIndex){
               updateSelection(null,null)
               removeSegmentDragControls();
           }else if(deleteSegIndex < segmentIndex){
               updateSelection(segmentIndex-1, null)
               setSegmentDragControls(segmentIndex-1,setPolygonDetails)
           }
           setDeleteSegIndex(null);
       }
       // delete with delete button press
       // else if( selectedIndices.obstacleIndex != null){
       //     deleteObstacle(segmentIndex,selectedIndices.obstacleIndex);
       //     updateObstacleList(selectedIndices.obstacleIndex);
       //     setSelectedIndices({segmentIndex: segmentIndex,obstacleIndex: null});
       // }
       // else if(segmentIndex != null){
       //     // deleteSingleSegment(selectedIndices.segmentIndex)
       // }
        setDeleteConfirmationVisible(false);
    };

    function updateSelection(segmentIndex, obstacleIndex){
        setSelectedIndices({segmentIndex: segmentIndex,obstacleIndex: obstacleIndex});
    }


    useEffect(() => {
        if (DEV_MOD) {
            setInformationChecked("found");
        } else if (token && designID) {
            verifyUser();
        } else {
            setInformationChecked("unauthenticated");
        }
    }, []);

    const verifyUser = () => {
        checkUser(token)
            .then((res) => {
                if (res.status === 200) {
                    setCurrentUser(res.data.data);
                    checkModelInfo();
                }
            })
            .catch((err) => {
                setInformationChecked("unauthenticated");
            });
    };

    const checkModelInfo = () => {
        getDesign(token, designID)
            .then((res) => {
                if (res.status === 200) {
                    setCurrentDesign(res.data.data);
                    setInformationChecked("found");
                }
            })
            .catch((err) => {
                setCurrentDesign(null);
                setInformationChecked("not-found");
            });
    };

    const handleUpdatePolygon = () => {
        let data = transformPolygonData()
        let polygonData = JSON.stringify(data);
        setIsLoading(true);

        updateProjectData(token, currentDesign.uuid, {"model_data": polygonData})
            .then((res) => {
                setIsLoading(false);
                return res;
            })
            .catch((error) => {
                handleErrors(error);
                setIsLoading(false);
            });
    };

    const handleGenerateDxf = () => {
        let data = transformPolygonData()
        generateDxf(data);
    }

    return (
        <>
            {informationChecked == null ? (
                <LoadingScreen informationChecked={informationChecked}/>
            ) : informationChecked === "unauthenticated" ? (
                <NotFound type={"token"}/>
            ) : informationChecked === "not-found" ? (
                <NotFound type={"design"}/>
            ) : (
                modelProgress < 98 && (
                    <LoadingScreen informationChecked={informationChecked}/>
                )
            )}
            {informationChecked === "found" && (
                <DesignerLayout
                    polygonLoading={isLoading}
                    polygonUpdate={handleUpdatePolygon}
                    currentProject={currentDesign}
                    type={modelProgress < 98 ? "hidden" : "visible"}
                    handleUpdatePolygon={handleUpdatePolygon}
                    handleGenerateDxf={handleGenerateDxf}
                    updateDrawingMode={updateDrawingMode}
                    currentDrawingMode ={currentDrawingMode}
                >
                    <Segment
                        updateObstacleType={updateObstacleType}
                        updateDrawingMode={updateDrawingMode}
                        currentDrawingMode={currentDrawingMode}
                        setDeleteConfirmationVisible={setDeleteConfirmationVisible}
                    />
                    <Main
                        setModelProgress={setModelProgress}
                        currentDesign={currentDesign}
                        currentDrawingModeRef={currentDrawingModeRef}
                        obstacleTypeRef={obstacleTypeRef}
                        updateDrawingMode={updateDrawingMode}
                        setDeleteConfirmationVisible={setDeleteConfirmationVisible}
                    />
                    { deleteConfirmationVisible &&(
                        <DeleteConfirmation
                            onConfirm={handleDeleteConfirmation}
                            onCancel={handleDeleteCancellation}
                        />
                    )}
                </DesignerLayout>
            )}
        </>
    );
};

export default Designer;