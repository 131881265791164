import {DrawingGlobals, DrawingModes} from "../Global/variables";


class KeyEventListener {
    constructor(completeShape,currentDrawingMode,undo,redo,
                setDeleteConfirmationVisible,selectedIndices, cancelDrawing,removeRuler,cancelSegmentDrawing) {

        this.completeShape= completeShape;
        this.currentDrawingMode=currentDrawingMode;
        this.undo =undo;
        this.redo =redo;
        this.deleteConfirmation =setDeleteConfirmationVisible;

        this.selectedindices = selectedIndices
        this.removeEventListener();
        this.cancelDrawing= cancelDrawing;
        this.removeRuler = removeRuler
        this.keyDownHandler = this.keyDownHandler.bind(this);
        this.cancelSegmentDrawing= cancelSegmentDrawing;

        window.addEventListener('keydown', this.keyDownHandler);

    }



    keyDownHandler(event) {
        switch (event.key) {
            case "Enter":
                if(this.currentDrawingMode === DrawingModes.SEGMENT && DrawingGlobals.intersectionPoints.length >= 3){
                    this.completeShape();
                }
                break;
            case "Escape":
                if(this.currentDrawingMode === DrawingModes.SEGMENT && DrawingGlobals.intersectionPoints.length > 0){
                    this.undo();
                }else if(this.currentDrawingMode === DrawingModes.SEGMENT && DrawingGlobals.intersectionPoints.length === 0){
                    this.cancelSegmentDrawing();
                }
                else if( this.currentDrawingMode === DrawingModes.OBSTACLE){
                    this.cancelDrawing();
                }else if(this.currentDrawingMode === DrawingModes.RULER){
                    this.removeRuler();
                }
                break;
            case "z":
                if (event.ctrlKey) {
                    if(this.currentDrawingMode === DrawingModes.SEGMENT && DrawingGlobals.intersectionPoints.length > 0) {
                    this.undo();
                    }
                }
                break;
            case "y":
                if (event.ctrlKey && this.currentDrawingMode === DrawingModes.SEGMENT) {
                    this.redo();
                }
                break;
            case "Delete":
                if(this.selectedindices.segmentIndex != null || this.selectedindices.obstacleIndex != null){
                    // this.deleteConfirmation(true)
                }
                break;
            case "Control":
                break;
        }
    }
    removeEventListener() {
        window.removeEventListener('keydown', this.keyDownHandler); // Remove the event listener
    }
    handleKeyUp(event) {
        // Handle keyup event
        // console.log('Key up:', event.key);
    }


}

export default KeyEventListener;

