import {DrawingGlobals, LABELS} from "../../Global/variables";
import * as THREE from "three";

let threshold =0.17
export function snap90Deg(prevSnapPoint, point) {
    if (prevSnapPoint != null && point.distanceTo(prevSnapPoint) <= threshold) {
        return prevSnapPoint;
    } else {
        let intersects = DrawingGlobals.intersectionPoints;
        let A = point;
        let B = intersects[intersects.length - 1];
        let C = intersects[intersects.length - 2];
        let snap90 = find90DegPoint(B, C, A);
        if (snap90.distanceTo(A) <= threshold) {
            return snap90
        } else {
            return null;
        }
    }
}

function find90DegPoint(B, C, A) {
    const BC = new THREE.Vector3().subVectors(C, B);
    const directionBC = BC.clone().normalize();
    const directionBMouse = A.clone().sub(B).normalize();

    const projectionBMouseOntoPlane = directionBMouse.clone().sub(directionBC.clone().multiplyScalar(directionBMouse.dot(directionBC)));
    const lengthBX = A.distanceTo(B);

    const BX = projectionBMouseOntoPlane.clone().multiplyScalar(lengthBX);
    return new THREE.Vector3().addVectors(B, BX);
}

export function showAngle(point) {
    const intersects = DrawingGlobals.intersectionPoints;
    if (intersects.length < 2) return;

    const B = intersects[intersects.length - 1];
    const C = intersects[intersects.length - 2];

    const BA = point.clone().sub(B);
    const BC = C.clone().sub(B);

    const dotProduct = BA.dot(BC);
    const magnitudeBA = BA.length();
    const magnitudeBC = BC.length();

    const angle = Math.acos(dotProduct / (magnitudeBA * magnitudeBC)) * THREE.MathUtils.RAD2DEG;

    LABELS.angle.visible = true;
    LABELS.angle.element.innerText = angle.toFixed(2) + "°";
    LABELS.angle.position.lerpVectors(point, B, 0.8);
}