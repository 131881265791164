// BubbleLoader.js
import React from "react";

const BubbleLoader = () => {
  return (
    <div className="bubble-loader-container">
      <div className="bubble-loader">
        <div className="bubble"></div>
        <div className="bubble"></div>
        <div className="bubble"></div>
        <div className="bubble"></div>
        <div className="bubble"></div>
      </div>
    </div>
  );
};

export default BubbleLoader;
