import { Card, Drawer, Typography } from 'antd'
import React from 'react'
import PhotoList from './PhotoList'

const { Title } = Typography

const PhotoDrawer = ({ isPhotoDrawerOpen, closePhotoDrawer, imagesLoading, dark }) => {
  return (
    <Drawer
      title={
        <Title level={4} className="my-0" style={{ color: !dark && '#444444' }}>
          Photos
        </Title>
      }
      width={860}
      open={isPhotoDrawerOpen}
      onClose={closePhotoDrawer}
    >
      <Card loading={imagesLoading} bodyStyle={{ padding: 0 }} bordered={false} style={{ boxShadow: 'none' }}>
        <PhotoList />
      </Card>
    </Drawer>
  )
}

export default PhotoDrawer
