import React from "react";
import AppHeader from "../components/AppHeader";
import ToolHeader from "../components/ToolHeader";
import { ConfigProvider} from "antd";
import { designerThemeDark, designerThemeLight } from "../theme";
import { useRecoilState } from "recoil";
import { themeState } from "../lib/atom";
import MapTileButton from "../components/DropDown";

export const DesignerLayout = ({
  children,
  type,
  currentProject,
  polygonUpdate,
  polygonLoading,
  handleUpdatePolygon,
  handleGenerateDxf,
  updateDrawingMode,
  currentDrawingMode

}) => {
  const [theme, setTheme] = useRecoilState(themeState);
  const handleThemeChange = () => setTheme(!theme);

    return (
    <div style={{ visibility: type, overflow: "hidden" }}>
      <ConfigProvider theme={theme ? designerThemeDark : designerThemeLight}>
        <AppHeader
            theme={theme}
            handleThemeChange={handleThemeChange}
            currentProject={currentProject}
        />
        <ToolHeader
            isLoading={polygonLoading}
            theme={theme}
            polygonUpdate={polygonUpdate}
            currentProject={currentProject}
            handleUpdatePolygon={handleUpdatePolygon}
            handleGenerateDxf={handleGenerateDxf}
            updateDrawingMode={updateDrawingMode}
            currentDrawingMode={currentDrawingMode}
        />
        {/*<div style={{ height: "calc(100vh - 74px)", overflow: "hidden" }}>*/}

        {/*  /!*<MapDropDownButton options={['Option 1', 'Option 2', 'Option 3']} />*!/*/}
        {/*  {children}*/}
        {/*  <MapToggleButton />*/}

        {/*</div>*/}
        <div style={{position: 'relative', height: "calc(100vh - 82.6px)", overflow: "hidden"}}>
          <div style={{position: 'fixed', bottom: '8px', right: '8px', zIndex: '999'}}>
           < MapTileButton />
          </div>
          {children}
        </div>
      </ConfigProvider>
    </div>
    );
};
