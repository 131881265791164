import React, {useEffect, useState} from 'react'
import { Button, Card, Collapse, Flex } from 'antd'
import { CaretRightOutlined } from '@ant-design/icons';
import {selectedIndicesState} from "../ThreeJs/ReactStates/states";
import {useRecoilState} from "recoil";
import {DrawingModes, ObstacleTypes, POLYGONS} from "../ThreeJs/Global/variables";
import {cleanUp, removeLineMeshes} from "../ThreeJs/Global/functions";
import {sceneManager} from "../ThreeJs/SetUps/SceneManager";
import {MdCheckBoxOutlineBlank, MdOutlineCircle} from "react-icons/md";
import {BsTriangle} from "react-icons/bs";
import {themeState} from "../lib/atom";
import {deselectSegment} from "../ThreeJs/Segments/Functions";
import {deselectObstacle} from "../ThreeJs/Obstacles/ObstacleFunctions";

const SegmentNavigator = ({items,onChange,title,updateDrawingMode,currentDrawingMode,updateObstacleType,
                          setShowObsTab,showObsTab}) => {
    const TabIcons = [MdCheckBoxOutlineBlank, MdOutlineCircle];
    const [activeObsTab, setActiveObsTab] = useState(null);
    const [selectedIndices, setSelectedIndices] = useRecoilState(selectedIndicesState)
    const [buttonText, setButtonText] = useState("");
    const [buttonType, setButtonType] = useState({});
    const [buttonStyle,setButtonStyle]= useState({});
    const theme = useRecoilState(themeState)[0];
    const [hoveredIndex, setHoveredIndex] = useState(null);
    useEffect(() => {
        if (currentDrawingMode === null || currentDrawingMode === DrawingModes.NONE ) {
            setButtonText('Add Segment');
            setButtonType('primary');
            setButtonStyle('primary')
        }else if (currentDrawingMode === DrawingModes.SEGMENT){
            setButtonText('Cancel Segment');
            setButtonType('primary');
            setButtonStyle('danger')
            deselect();
        }
    }, [currentDrawingMode]);


    function deselect(){
        let selectedSegment = selectedIndices.segmentIndex;
        let selectedObstacle = selectedIndices.obstacleIndex;
        if(selectedSegment !=  null){
            deselectSegment(selectedSegment)
        }
        if(selectedObstacle !== null){
            let selectedObs =POLYGONS[selectedSegment].obstructions[ selectedIndices.obstacleIndex].mesh;
            deselectObstacle(selectedObs)
        }
        setSelectedIndices({segmentIndex: null,obstacleIndex: null});
    }
    const handleObsTabClick = (index) => {
        const shapes = [ObstacleTypes.RECTANGLE, ObstacleTypes.CIRCLE, "triangle"];
        const clickedShape = shapes[index];
        setActiveObsTab(index);
        setShowObsTab(false);
        updateObstacleType(clickedShape);
        updateDrawingMode(DrawingModes.OBSTACLE)
    };


    const handleAddSegmentClick = () => {
        if (currentDrawingMode === null || currentDrawingMode === DrawingModes.NONE || currentDrawingMode === DrawingModes.PANEL) {
            updateDrawingMode(DrawingModes.SEGMENT);
        }
         else if(currentDrawingMode === DrawingModes.SEGMENT){
            updateDrawingMode(DrawingModes.NONE);
            removeLineMeshes(sceneManager.scene);
            cleanUp();
         }
    };
    const handleCancelObsClick = () =>{
        updateDrawingMode(DrawingModes.NONE)
        setShowObsTab(false);

    }
    useEffect(() => {
        if(currentDrawingMode === DrawingModes.NONE){
            setActiveObsTab(null);
        }
    }, [currentDrawingMode]);


  return (
      <Card
          title={title}
          bodyStyle={{padding: "0"}}
          className='segment__card'
      >
          {items.length>0  && showObsTab === false && activeObsTab == null && currentDrawingMode !== DrawingModes.SEGMENT ?(
              <Flex vertical style={{height: '150px', overflow: 'auto'}}
                    className={` ${theme ? 'custom-scrollbar-dark' : ''}`}>

                  <Collapse
                      accordion
                      items={items}
                      activeKey={selectedIndices.segmentIndex}
                      expandIcon={({isActive}) => (
                          <CaretRightOutlined style={{color: '#ccc'}} rotate={isActive ? 90 : 0}/>
                      )}

                      defaultActiveKey={['1']}
                      onChange={onChange}
                  />
              </Flex>
          )  : currentDrawingMode === DrawingModes.SEGMENT ? (
              <div style={{ textAlign: 'center', fontSize: '16px', margin: '40px' }} className={`${!theme ? 'text-black' : 'text-light'}`}>
                  Draw Segment On Screen
              </div>
          ) : showObsTab === true && activeObsTab == null ? (
              <div style={{margin:'30px 0'}}>
                  <div style={{ textAlign: 'center', marginBottom: '10px' }}>Pick a module/obstruction</div>
                  <Flex horizontal style={{ justifyContent: 'center'}}>
                      {TabIcons.map((Icon, index) => (
                          <Card
                              key={index}
                              style={{
                                  width: '45px',
                                  height: '45px',
                                  margin: '0 9px',
                                  borderRadius: '12px',
                                  background: hoveredIndex === index ? (theme ? '#007bff' : '#007bff') : (theme ? '#CBCBCB' : '#CBCBCB'),
                                  textAlign: 'center',
                                  cursor: 'pointer',
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  paddingTop: '5px',
                                  boxShadow: hoveredIndex === index ? '0px 4px 8px rgba(0, 0, 0, 0.1)' : 'none'
                              }}
                              onClick={() => handleObsTabClick(index)}
                              onMouseEnter={() => setHoveredIndex(index)}
                              onMouseLeave={() => setHoveredIndex(null)}
                          >
                              <Icon
                                  size={30}
                                  color={index === hoveredIndex ? '#fff' : (theme ? '#676767' : '#676767')}
                              />
                          </Card>
                      ))}
                  </Flex>
              </div>
          ):activeObsTab != null ? (
              <div style={{textAlign: 'center', fontSize: "16px", margin: "40px"}} className={`${!theme ? 'text-black' : 'text-light'}`}>
                 Start Drawing
              </div>
              ):(
              <div style={{textAlign: 'center', fontSize: "16px", margin: "40px"}} className={`${!theme ? 'text-black' : 'text-light'}`}>
                 No Segments Found
              </div>

          )}

          {showObsTab === false && activeObsTab == null? (
              <div style={{marginTop: '30px'}}>
                  <Button block type={buttonType} danger={buttonStyle === 'danger'} onClick={handleAddSegmentClick}>
                      {buttonText}
                  </Button>
              </div>
          ) : <div style={{marginTop: '30px'}}>
              <Button block type="primary" danger onClick={handleCancelObsClick}>
                  Cancel
              </Button>
          </div>}

      </Card>
  )
}

export default SegmentNavigator