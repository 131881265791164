import React from 'react';
import { Image, Row, Col, Modal, Flex, Typography, Divider } from 'antd';
import { bytesToSize, convertDMSToDD } from '../helpers/CONSTANT';

const PhotoMetaData = ({ openMetaModal, onClose, metaData }) => {
  const { latitude, longitude } = convertDMSToDD(
    metaData?.exif?.GPS?.GPSLatitude,
    metaData?.exif?.GPS?.GPSLatitudeRef,
    metaData?.exif?.GPS?.GPSLongitude,
    metaData?.exif?.GPS?.GPSLongitudeRef,
  );

  return (
    <Modal
      title={
        <Typography.Title level={5} className="my-0" strong>
          {metaData.name}
        </Typography.Title>
      }
      width={900}
      footer={null}
      centered
      open={openMetaModal}
      onCancel={onClose}
    >
      <Row gutter={[24, 24]} style={{ marginTop: '10px' }}>
        <Col xs={24} md={12}>
          <Image
            preview={false}
            style={{ borderRadius: '10px', objectFit: 'cover', width: '100%' }}
            src={metaData?.image}
            // width={340}
            // height={300}
            alt="Image"
          />
        </Col>
        <Col xs={24} md={12}>
          <Flex align="center">
            <Flex flex={1}>
              <Typography.Title level={5} className="my-0">
                Make
              </Typography.Title>
            </Flex>
            <Flex flex={1}>{metaData?.exif?.IFD0?.Make}</Flex>
          </Flex>
          <Divider style={{ height: 'auto', marginBlock: '5px' }} />
          <Flex align="center">
            <Flex flex={1}>
              <Typography.Title level={5} className="my-0">
                Model
              </Typography.Title>
            </Flex>
            <Flex flex={1}>{metaData?.exif?.IFD0?.Model}</Flex>
          </Flex>
          <Divider style={{ height: 'auto', marginBlock: '5px' }} />
          <Flex align="center">
            <Flex flex={1}>
              <Typography.Title level={5} className="my-0">
                Mime Type
              </Typography.Title>
            </Flex>
            <Flex flex={1}>{metaData?.exif?.FILE?.MimeType}</Flex>
          </Flex>
          <Divider style={{ height: 'auto', marginBlock: '5px' }} />
          <Flex align="center">
            <Flex flex={1}>
              <Typography.Title level={5} className="my-0">
                Width
              </Typography.Title>
            </Flex>
            <Flex flex={1}>{metaData?.exif?.COMPUTED?.Width}</Flex>
          </Flex>
          <Divider style={{ height: 'auto', marginBlock: '5px' }} />
          <Flex align="center">
            <Flex flex={1}>
              <Typography.Title level={5} className="my-0">
                Height
              </Typography.Title>
            </Flex>
            <Flex flex={1}>{metaData?.exif?.COMPUTED?.Height}</Flex>
          </Flex>
          <Divider style={{ height: 'auto', marginBlock: '5px' }} />
          <Flex align="center">
            <Flex flex={1}>
              <Typography.Title level={5} className="my-0">
                Latitude
              </Typography.Title>
            </Flex>
            <Flex flex={1}>{latitude}</Flex>
          </Flex>
          <Divider style={{ height: 'auto', marginBlock: '5px' }} />
          <Flex align="center">
            <Flex flex={1}>
              <Typography.Title level={5} className="my-0">
                Longitude
              </Typography.Title>
            </Flex>
            <Flex flex={1}>{longitude}</Flex>
          </Flex>
          <Divider style={{ height: 'auto', marginBlock: '5px' }} />
          <Flex align="center">
            <Flex flex={1}>
              <Typography.Title level={5} className="my-0">
                DateTime
              </Typography.Title>
            </Flex>
            <Flex flex={1}>{metaData?.exif?.EXIF?.DateTimeOriginal}</Flex>
          </Flex>
          <Divider style={{ height: 'auto', marginBlock: '5px' }} />
          <Flex align="center">
            <Flex flex={1}>
              <Typography.Title level={5} className="my-0">
                FileSize
              </Typography.Title>
            </Flex>
            <Flex flex={1}>{bytesToSize(metaData?.exif?.FILE?.FileSize)}</Flex>
          </Flex>
        </Col>
      </Row>
    </Modal>
  );
};

export default PhotoMetaData;