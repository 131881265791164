import * as THREE from 'three';
import {sceneManager} from "../../SetUps/SceneManager";
import {createSegmentPlane} from "../../Global/functions";

// Function to create a plane from three points
let panel = null;

// Function to calculate the four corners of the panel based on the plane
function getPanelCorners(center, width, height, plane) {
    // Calculate half dimensions
    const halfWidth = width / 2;
    const halfHeight = height / 2;

    // Plane normal
    const planeNormal = plane.normal.clone().normalize();

    // Apply a small offset along the plane's normal to lift the panel above the mesh
    const offset = 0.01; // Adjust this value as needed
    const liftedCenter = center.clone().add(planeNormal.clone().multiplyScalar(offset));

    // Determine a basis for the panel's orientation in the plane
    let referenceVector = new THREE.Vector3(1, 0, 0);
    if (planeNormal.equals(referenceVector) || planeNormal.equals(referenceVector.negate())) {
        referenceVector = new THREE.Vector3(0, 0, 1);
    }
    const rightVector = new THREE.Vector3().crossVectors(planeNormal, referenceVector).normalize();
    const upVector = new THREE.Vector3().crossVectors(planeNormal, rightVector).normalize();

    // Compute the corner points
    const corners = [
        liftedCenter.clone().sub(rightVector.clone().multiplyScalar(halfWidth)).sub(upVector.clone().multiplyScalar(halfHeight)),
        liftedCenter.clone().add(rightVector.clone().multiplyScalar(halfWidth)).sub(upVector.clone().multiplyScalar(halfHeight)),
        liftedCenter.clone().add(rightVector.clone().multiplyScalar(halfWidth)).add(upVector.clone().multiplyScalar(halfHeight)),
        liftedCenter.clone().sub(rightVector.clone().multiplyScalar(halfWidth)).add(upVector.clone().multiplyScalar(halfHeight))
    ];

    return corners;
}
// Function to create a BufferGeometry for the panel
export function createPanelGeometry(corners) {
    const geometry = new THREE.BufferGeometry();
    const positions = new Float32Array([
        corners[0].x, corners[0].y, corners[0].z,
        corners[1].x, corners[1].y, corners[1].z,
        corners[2].x, corners[2].y, corners[2].z,
        corners[2].x, corners[2].y, corners[2].z,
        corners[3].x, corners[3].y, corners[3].z,
        corners[0].x, corners[0].y, corners[0].z
    ]);

    // Define UVs for the corners
    const uvs = new Float32Array([
        0, 0, // Bottom-left
        1, 0, // Bottom-right
        1, 1, // Top-right
        0, 1  // Top-left
    ]);

    // Indices to define two triangles (the panel)
    const indices = new Uint16Array([0, 1, 2, 2, 3, 0]);

    geometry.setAttribute('position', new THREE.BufferAttribute(positions, 3));
    geometry.setAttribute('uv', new THREE.BufferAttribute(uvs, 2));
    geometry.setIndex(new THREE.BufferAttribute(indices, 1));
    geometry.computeVertexNormals(); // Important for correct shading

    return geometry;
}

function updatePanelPosition(mousePosition, plane, selectedPanels) {
    const width = 1; // meters
    const height = 1; // meters

    // Get the four corner points of the panel
    const corners = getPanelCorners(mousePosition, width, height, plane);

    const textureLoader = new THREE.TextureLoader();
    textureLoader.load(
        selectedPanels,
        (texture) => {
            // Set texture wrapping and repeat if necessary
            texture.wrapS = THREE.RepeatWrapping;
            texture.wrapT = THREE.RepeatWrapping;
            texture.repeat.set(1, 1); // Adjust this if you want to tile the texture

            // Create or update the geometry
            const geometry = createPanelGeometry(corners);

            if (!panel) {
                // If the panel does not exist, create it with the texture
                const material = new THREE.MeshStandardMaterial({ map: texture, roughness: 0.8 });
                panel = new THREE.Mesh(geometry, material);
                panel.renderOrder = 1;
                panel.frustumCulled = false;
                sceneManager.scene.add(panel);
            } else {
                // If the panel exists, update its geometry and reassign the material
                panel.geometry.dispose(); // Clean up previous geometry
                panel.geometry = geometry;
                panel.material.map = texture; // Ensure the material still has the texture
                panel.material.needsUpdate = true; // Force the material to update
            }
        },
        undefined,
        (error) => {
            console.error('Error loading texture:', error);
        }
    );
}

export function getPlaneIntersect(mouse,selectedPanels){

    // Create a raycaster and update the raycaster with the camera and mouse coordinates
    const raycaster = new THREE.Raycaster();
    raycaster.setFromCamera(mouse, sceneManager.camera);

    // Create the plane from the segment
    const index = 0; // Adjust index as needed
    const plane = createSegmentPlane(index);

    // Calculate the intersection point with the plane
    const intersection = new THREE.Vector3();
    raycaster.ray.intersectPlane(plane, intersection);



    updatePanelPosition(intersection, plane,selectedPanels);

}

export function dropPanel(){
    panel=null;
}


