import * as THREE from "three";
import { DXF_VARS } from "../../Global/variables";

export function projectDormerTo2D(dormerPoints) {
    let projectedPoints = dormerPoints.map(point => {
        point = new THREE.Vector3(point.x, point.y, point.z);
        const xCoordinate = point.dot(DXF_VARS.vectorU);
        const yCoordinate = point.dot(DXF_VARS.vectorV);
        return new THREE.Vector2(xCoordinate, yCoordinate);
    });
    projectedPoints.push(projectedPoints[0]);

    return dormerPositioning(projectedPoints);
}

function dormerPositioning(dormerPoints) {
    let origin = DXF_VARS.origin;
    const translationVector = new THREE.Vector2(-origin.x, -origin.y);

    // Translate dormer points relative to the segment origin
    const translatedDormerPoints = dormerPoints.map(point => {
        const translatedPoint = point.clone().add(translationVector);
        return { x: translatedPoint.x, y: translatedPoint.y };
    });

    let lineAngle = DXF_VARS.rotationAngle;
    // Rotate dormer points based on the rotation angle
    const rotatedDormerPoints = translatedDormerPoints.map(point => {
        const rotatedX = Math.cos(-lineAngle) * point.x - Math.sin(-lineAngle) * point.y;
        const rotatedY = Math.sin(-lineAngle) * point.x + Math.cos(-lineAngle) * point.y;
        return new THREE.Vector3(rotatedX, rotatedY, point.z);
    });
    // Flip dormer points vertically if necessary
    if (DXF_VARS.isVerticalFlipped) {
        rotatedDormerPoints.forEach(point => {
            point.y = -point.y;
        });
    }
    return rotatedDormerPoints;
}
