import React, {useState} from "react";
import {
    Card,
    Flex,
    Typography,
    Button,
    Row, Col
} from "antd";
import {MdOutlineCircle, MdCheckBoxOutlineBlank} from "react-icons/md";
import {BsTriangle} from "react-icons/bs";
import {IoMdArrowDropright} from "react-icons/io";
import SegmentDetail from "./SegmentDetail";
import SegmentNavigator from "./SegmentNavigator";
import {useRecoilState, useRecoilValue, useSetRecoilState} from "recoil";
import {themeState} from "../lib/atom";
import {DeleteOutlined, PlusOutlined} from "@ant-design/icons";
import ObstacleDetail from "./ObstacleDetail";
import {
    deleteObstacleIndex,
    deleteSegmentIndex,
    polygonsState,
    selectedIndicesState
} from "../ThreeJs/ReactStates/states";
import {selectSegment} from "../ThreeJs/Segments/Functions";
import {selectObstacle} from "../ThreeJs/Obstacles/ObstacleFunctions";
import {updatePolygonDetailsSelector} from "../ThreeJs/ReactStates/updates";

const Segment = ({
                     updateObstacleType,
                     updateDrawingMode,
                     currentDrawingMode,
                     setDeleteConfirmationVisible
                 }) => {
    let items;
    const theme = useRecoilState(themeState)[0];
    const polygonState = useRecoilValue(polygonsState);
    const [selectedIndices, setSelectedIndices] = useRecoilState(selectedIndicesState);
    const setDeleteSegment = useSetRecoilState(deleteSegmentIndex);
    const setDeleteObstacle = useSetRecoilState(deleteObstacleIndex);
    const setPolygonDetails = useSetRecoilState(updatePolygonDetailsSelector)
    const [showObsTab, setShowObsTab] = useState(false);

    const handleSegmentClick = (index) => {
        selectSegment(selectedIndices, index, setSelectedIndices, setPolygonDetails)
    };

    const handleObstructionClick = (segmentIndex, index) => {

        // setSelectedIndices({segmentIndex: segmentIndex, obstacleIndex: index});
        selectObstacle(selectedIndices, index, setSelectedIndices)
    };


    const handleDeleteObstacle = (index, obsIndex) => {
        setDeleteObstacle(obsIndex);
        setDeleteConfirmationVisible(true)
    }

    const handleDeleteSegment = (index) => {
        setDeleteSegment(index)
        setDeleteConfirmationVisible(true)
    };

    items =
        polygonState.length > 0
            ? polygonState.map((polygon, index) => {
                const segmentName = polygon.label;
                const obstacles = polygon.obstructions;
                const isSegmentSelected = selectedIndices.segmentIndex === index;

                return {
                    key: index,
                    label: (
                        <Row key={index} align="middle" className="segment-item">
                            <Col flex="auto">
                                <Typography.Paragraph
                                    strong
                                    style={{marginBottom: 0}}
                                    className={`text-${isSegmentSelected ? "blue" : "gray-1"} segment-name`}
                                    onClick={() => handleSegmentClick(index)}
                                >
                                    Segment {segmentName}
                                </Typography.Paragraph>
                            </Col>
                            <Col>
                                <Button danger
                                        type={"text"}
                                        onClick={() => handleDeleteSegment(index)}
                                        className="delete-button"
                                        icon={<DeleteOutlined
                                            className="delete-icon"
                                        />}
                                />
                            </Col>
                        </Row>
                    ),
                    children: (
                        <Flex vertical gap={"small"}>
                            {obstacles.map((obs, obsIndex) => (
                                <Flex

                                    style={{
                                        alignItems: "center",
                                        justifyContent: "space-between"
                                    }}

                                    key={obsIndex}
                                >

                                    <Flex gap="small"
                                          className={`${theme? (selectedIndices.obstacleIndex === obsIndex
                                              ? "text-light"
                                              : "text-gray-1 fw-500"):(selectedIndices.obstacleIndex === obsIndex
                                              ? "text-black"
                                              : "text-gray-1 fw-500")
                                              
                                          }`}
                                          style={{marginLeft: "40px",}}
                                    >
                                        {obs.type === "rectangle" ? (
                                            <MdCheckBoxOutlineBlank style={{fontSize: "17px"}}/>
                                        ) : obs.type === "circle" ? (
                                            <MdOutlineCircle style={{fontSize: "17px"}}/>
                                        ) : (
                                            <BsTriangle style={{fontSize: "17px"}}/>
                                        )}

                                        <Typography.Text
                                            style={{
                                                fontSize: "12px",
                                                transition: "color 0.3s",
                                            }}
                                            className={`${
                                                selectedIndices.obstacleIndex === obsIndex && isSegmentSelected
                                                    ? "blue"
                                                    : "text-gray-1"
                                            } `}
                                            onClick={() => {
                                                handleObstructionClick(index, obsIndex);
                                            }}
                                        >
                                            {`${obs.type.charAt(0).toUpperCase() + obs.type.slice(1).toLowerCase()}  `}
                                            {obsIndex + 1}
                                        </Typography.Text>
                                    </Flex>

                                    <Button
                                        type={"text"}
                                        onClick={() => handleDeleteObstacle(index, obsIndex)}
                                        className="delete-button"
                                        style={{marginRight: "15px"}}
                                        icon={<DeleteOutlined className="delete-icon"/>}
                                    />

                                </Flex>

                            ))}
                            {isSegmentSelected && (
                                <Button
                                    type={"text"}
                                    className={`text-gray-1`}
                                    onClick={() => setShowObsTab(true)}

                                >
                                    <PlusOutlined/> Add Obstruction
                                </Button>
                                // <Button
                                //     type={"text"}
                                //     className={`text-gray-1`}
                                //    disabled={true}
                                //
                                // >
                                //     <PlusOutlined/> Add Module
                                // </Button>
                            )
                            }
                        </Flex>
                    ),
                }
                    ;
            })
            : (
                <div key="empty" style={{textAlign: 'center', padding: '20px'}}>
                    <h1>No segment Found</h1>
                </div>
            );


    const onChange = (key) => {
    };
    const title = (
        <Flex justify="space-between" align="center">
            <Typography.Title
                style={{fontSize: "16px", fontWeight: "400"}}
                className={`${theme ? "text-light my-0" : "text-black-1 my-0"}`}
            >
                Segments Navigator
            </Typography.Title>
            <IoMdArrowDropright color="#CFCFCF" size={24}/>
        </Flex>
    );
    const titleDetails = (
        <Flex justify="space-between" align="center" className="text-black-1 my-0">
            <Typography.Title
                style={{fontSize: "16px", fontWeight: "400"}}
                className={`${theme ? "text-light my-0" : "text-black-1 my-0"}`}
            >
                Segment Details
            </Typography.Title>
        </Flex>
    );
    return (
        <div className="relative">
            {/*<Flex gap={0}  vertical className='relative'>*/}
            {/*  <CubeScene/>*/}
            {/*  <Image width={180} src={circle_3d} alt='circle_3d' preview={false} style={{  position:'absolute', top:" -80px", left:'15px' }}/>*/}
            {/*  <Image width={80} src={polygon_right} alt='polygon_right' preview={false} style={{ position:'absolute', top:" -40px", left:'130px' }}/>*/}
            {/*  <Image width={80} src={polygon_left} alt='polygon_left' preview={false} style={{ position:'absolute', top:" -40px", left:'-10px' }}/>*/}
            {/*  <Image width={80} src={polygon_top} alt='polygon_top' preview={false} style={{ position:'absolute', top:" -120px", left:'125px' }}/>*/}
            {/*  <Slider vertical defaultValue={30} style={{ position:'absolute',left:'250px' }} />*/}
            {/*  <Flex gap={'small'} style={{ position:'absolute', top:'170px', left: '20px' }}>*/}
            {/*    <Button icon={<Image src={map_icon} preview={false}/>}>Google Maps</Button>*/}
            {/*    <Button icon={<Image src={hand} preview={false}/>}/>*/}
            {/*    <Button icon={<Image src={plus} preview={false}/>}/>*/}
            {/*    <Button icon={<Image src={minus} preview={false}/>}/>*/}
            {/*  </Flex>*/}
            {/*</Flex>*/}
            <Flex className="segment__position" vertical align="end">
                <Flex gap={"small"} align="start">
                    <SegmentNavigator
                        items={items}
                        onChange={onChange}
                        title={title}
                        updateDrawingMode={updateDrawingMode}
                        currentDrawingMode={currentDrawingMode}
                        updateObstacleType={updateObstacleType}
                        setShowObsTab={setShowObsTab}
                        showObsTab={showObsTab}
                    />
                </Flex>
                {/* TODO: and if statement for segmnet as well*/}
                {selectedIndices.obstacleIndex !== null ? (
                    <Card style={{marginTop: "10px", width: "275px"}} bodyStyle={{padding: "10px"}}>
                        <ObstacleDetail
                        />
                    </Card>
                ) : null}
                {selectedIndices.segmentIndex !== null ? (
                    <SegmentDetail
                        updateDrawingMode={updateDrawingMode}
                        titleDetails={titleDetails}
                    />) : null}
                {/*<SegmentSetting titleDetails={titleDetails} />*/}
            </Flex>
        </div>
    );
};

export default Segment;
