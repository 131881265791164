import React, { useEffect, useState } from "react";
import LoaderLogo from "../../assets/loader-logo.svg";
import { Flex, Image, Typography } from "antd";
import { Link } from "react-router-dom";

const NotFound = ({ type }) => {
  return (
    <Flex justify="center" align="center" className="h-100" vertical>
      <Image
        preview={false}
        width={110}
        src={LoaderLogo}
        alt="loader-logo"
        style={{ marginBottom: "20px" }}
      />
      <Typography.Paragraph style={{ maxWidth: "585px", marginTop: "20px" }}>
        {type === "token" ? (
          <pre>
            You are trying to access Designer tool with an invalid token. We recommend to first
            login to your Eagle Eye CRM and then click Designer Tool to access it.
          </pre>
        ) : (
          <pre>
            You are trying to access Designer tool with an invalid UUID. We recommend to first login
            to your Eagle Eye CRM and then click Designer Tool to access it.{" "}
          </pre>
        )}
      </Typography.Paragraph>
      <Link to={"/"} className="fw-500" style={{ marginTop: "20px" }}>
        Back to Eagle Eye
      </Link>
      <Typography.Text type="secondary" strong style={{ marginTop: "40px" }}>
        © THE EAGLE EYE TEAM
      </Typography.Text>
      <Typography.Text
        type="secondary"
        strong
        style={{ marginTop: "10px", maxWidth: "585px", textAlign: "center" }}
      >
        Eagle Eye creates high-end roofing and solar software tools that aim to manage projects
        easier than before.
      </Typography.Text>
    </Flex>
  );
};

export default NotFound;
