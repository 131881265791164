import * as THREE from 'three';
import { OrbitControls } from "three/addons/controls/OrbitControls";
import { LABELS} from "../Global/variables";
import {CSS2DRenderer} from "three/examples/jsm/renderers/CSS2DRenderer";
import {LINES, OBSTACLE} from "../Global/materials";
import {createLabelDiv} from "../Global/functions";


class SceneManager {
    constructor() {
        //using singleton approach since only one scene instance is required
        if (!SceneManager.instance) {
            this.initialize();
            SceneManager.instance = this;
        }
        return SceneManager.instance;
    }

    initialize() {
        this.scene = new THREE.Scene();
        this.height =window.innerHeight - 82.6;
        this.camera = new THREE.PerspectiveCamera(45, window.innerWidth / this.height, 0.1, 5000);
        this.camera.up.set(0, 0, 1);
        this.renderer = new THREE.WebGLRenderer({ antialias: true });
        document.body.appendChild(this.renderer.domElement);
        this.renderer.outputColorSpace =THREE.SRGBColorSpace;
        this.renderer.setClearColor(0x000000)
        this.renderer.setSize(window.innerWidth, this.height);

        this.controls = new OrbitControls(this.camera, this.renderer.domElement);
        this.directionalLight = new THREE.DirectionalLight(0xffffff, 5);
        this.ambientLight = new THREE.AmbientLight(0xffffff, 0.8);
        this.labelRenderer = null;
        this.controls.addEventListener('start', this.onStartCameraRotation);
        this.controls.addEventListener('end', this.onEndCameraRotation);

        this.setUpScene();

    }

    setUpScene(){

        this.setControl();
        this.setUpLabelRenderer();
        this.setUpRefrenceAngle();
        this.setUpRefrenceLine();
        this.setDirectionalLights();
        this.setAmbientLight();
        this.scene.add(OBSTACLE.circle);
        this.scene.add(OBSTACLE.box);
    }

    setControl() {

        this.camera.position.set(-50, 0, 10);
        this.controls.listenToKeyEvents(window);
        this.controls.mouseButtons = {
            LEFT: null,
            MIDDLE: THREE.MOUSE.PAN,
            RIGHT: THREE.MOUSE.ROTATE,
        };
        this.controls.zoomToCursor = true;
        this.controls.rotateSpeed = 1;
        this.controls.enablePan = true;
        // this.controls.minPolarAngle = 0;
        // this.controls.maxPolarAngle = 60 * (Math.PI / 180);
        this.controls.target.set(0, 0, 0);
        this.controls.update();
         // adding axis helper
         // let axis = new THREE.AxesHelper(250);
         // this.scene.add(axis)
    }

    setDirectionalLights(){
        this.directionalLight.position.set(50, 50, 50);
        this.directionalLight.castShadow = true;
        this.directionalLight.shadow.mapSize.width = 512;
        this.directionalLight.shadow.mapSize.height = 512;
        this.directionalLight.shadow.camera.near = 0.5;
        this.directionalLight.shadow.camera.far = 500;

        this.scene.add(this.directionalLight)
    }

    setAmbientLight(){
        this.scene.add(this.ambientLight);
    }

    setUpLabelRenderer() {
        this.labelRenderer = new CSS2DRenderer();
        this.labelRenderer.setSize(window.innerWidth,this.height);
        this.labelRenderer.domElement.style.position = 'fixed';
        this.labelRenderer.domElement.style.top = '72px';
        this.labelRenderer.domElement.style.pointerEvents = 'none';
        this.labelRenderer.domElement.style.fontSize = '14px';
        this.labelRenderer.domElement.style.textShadow = '1px 1px 3px rgba(0, 0, 0, 0.8)';
        this.labelRenderer.domElement.style.webkitTextStroke = '0.8px #ffffff';
        this.labelRenderer.domElement.style.transform = 'rotateX(0) rotateY(0) rotateZ(0)';
    }

    setUpRefrenceLine(){
        LINES.referenceLine.computeLineDistances();
        LINES.referenceLine.visible= false;
        this.scene.add(LINES.referenceLine);
    }

    setUpRefrenceAngle(){
        LABELS.angle = createLabelDiv("","0°")
        LABELS.angle.visible = false;
        this.scene.add(LABELS.angle);
    }

    handleResize() {
        this.camera.aspect = window.innerWidth / this.height;
        this.camera.updateProjectionMatrix();
        this.renderer.setSize(window.innerWidth, window.innerHeight - 82.6);
        this.renderer.render(this.scene, this.camera);
        this.labelRenderer.setSize(window.innerWidth, this.height);
    }

    onStartCameraRotation() {
        this.isCameraRotating = true;
    }

    onEndCameraRotation() {
        this.isCameraRotating = false;
    }

    getSceneObjects(){

        return {
            scene: this.scene,
            camera: this.camera,
            renderer: this.renderer,
            controls: this.controls,
            labelRenderer: this.labelRenderer,
        };

    }
}
export const sceneManager = new SceneManager();