
import {Button, Card, Flex, Image, Input, Space, Typography} from 'antd'
import React, {useEffect, useState} from 'react'
import black_svg from '../assets/black.svg'
import down_arrow from '../assets/down_arrow.svg'
import grid_add from '../assets/grid_add.svg'
import grid_drag from '../assets/grid_drag.svg'
import grid_delete from '../assets/grid_delete.svg'
import grid_copy from '../assets/grid_copy.svg'
import IconButton from './IconButton'

import solarPanelImages from '../data/solarPanelImages.json';
import {useRecoilState, useRecoilValue, useSetRecoilState} from "recoil";
import {
    addPanels,
    avaArea,
    deleteSetBacks,
    editSetBacks,
    isSetBackAdded,
    polygonDetailsSelector,
    selectedIndicesState, selectedPanel, setBackInput, solarPanel
} from "../ThreeJs/ReactStates/states";

import {DeleteOutlined, EditOutlined, PlusOutlined} from "@ant-design/icons";

import {DrawingModes, POLYGONS} from "../ThreeJs/Global/variables";
import {deleteSetback, updateAvaArea, updateSetbackUI} from "../ThreeJs/Segments/SetBacks/Functions";


const SegmentDetail = ({ titleDetails,updateDrawingMode }) => {

    const details = useRecoilValue(polygonDetailsSelector)
    const [showSetBack,setShowSetBack] = useRecoilState(isSetBackAdded);
    const [editSetback,setEditSetback] = useRecoilState(editSetBacks);
    const [deleteSetBack,setDeleteSetBack] = useRecoilState(deleteSetBacks);
    const selectedIndices = useRecoilValue(selectedIndicesState);
    let setInputValues = useSetRecoilState(setBackInput);
    const [inputValue, setInputValue] = useState(0);
    const [availableArea, setAvailableArea] = useRecoilState(avaArea);

    useEffect(() => {
        let selectedSeg = POLYGONS[selectedIndices.segmentIndex];
        if(deleteSetBack && selectedSeg.setBacks.isSet){
            if(editSetBacks){
                setEditSetback(false);
                setInputValues(false);
                setShowSetBack(false);
            }
            deleteSetback(selectedIndices.segmentIndex)
            setDeleteSetBack(false);
        }
    }, [deleteSetBack]);

    useEffect(() => {
        if (selectedIndices.segmentIndex !== null && showSetBack) {
            const initialInputValues = {};
            POLYGONS[selectedIndices.segmentIndex].setBacks.edgesData.forEach((edge, index) => {
                initialInputValues[index] = edge.value;
            });
            setInputValues(initialInputValues);
        }
    }, [editSetback]);

    useEffect(() => {
        if(selectedIndices.segmentIndex !=null){
            let isSetback = POLYGONS[selectedIndices.segmentIndex].setBacks.isSet;
            setShowSetBack(isSetback);
        }
    }, [selectedIndices.segmentIndex]);

    useEffect(() => {
        let isSetBack = POLYGONS[selectedIndices.segmentIndex].setBacks.isSet;
        // issue the showSetback is true before pressing button, the above useEffect wasn't working properly
        // solution : i used inputValue>0 to fix it.
        if (showSetBack  && inputValue > 0 && !isSetBack ) {
            let index = selectedIndices.segmentIndex
            updateSetbackUI(index,setInputValues,inputValue);
            updateAvaArea(index,setAvailableArea)
        }

    }, [showSetBack]);


    const handleAddSetback = () => {
        setShowSetBack(true);
        setEditSetback(true);
    };

    const handleDelete = () => {
        setDeleteSetBack(true);
    };

    const handleEdit = () => {
        setEditSetback(!editSetback);
    };

    const handleInputChange = (e) => {
        const value = e.target.value;
        if (/^\d*$/.test(value)) {
            setInputValue(value);
        }
    };


    return (
        <div style={{marginTop: "10px"}}>
    <Card style={{ fontSize:'16px'}}  title={titleDetails} bodyStyle={{ padding: 0 ,fontSize: '16px'}}  className='segment__card'>
        <Flex justify='space-between' align='center' style={{ padding: '14px' }}>
            <Space.Compact direction='vertical'>
                <Typography.Text strong>
                    Pitch
                </Typography.Text>
                <Typography.Text style={{ fontSize:'12px' }}>
                    {details.pitch !== null ? `${details.pitch}°` : 'N/A'}
                </Typography.Text>
            </Space.Compact>
            <Space.Compact direction='vertical'>
                <Typography.Text strong>
                    Azimuth
                </Typography.Text>
                <Typography.Text style={{ fontSize:'12px' }}>
                    {details.azimu !== null ? `${details.azimu}°` : 'N/A'}
                </Typography.Text>
            </Space.Compact>
            <Space.Compact direction='vertical'>
                <Typography.Text strong>
                    Area
                </Typography.Text>
                <Typography.Text style={{ fontSize:'12px' }}>
                    {details.area !== null ? `${parseFloat(details.area).toFixed(2)} sqft` : 'N/A'}
                </Typography.Text>
            </Space.Compact>
        </Flex>

        {/*<Card style={{ fontSize:'16px'}}  title={titleDetails} bodyStyle={{ padding: 0 ,fontSize: '16px'}}  className='segment__card'>*/}

        {/*</Card>*/}
        {/*<Flex gap={'small'} className='panel-image'>*/}
        {/*    <Image src={black_svg} alt='black' />*/}
        {/*    <Flex justify='space-between' align='center' className='w-100'>*/}
        {/*        <Flex vertical gap={0}>*/}
        {/*            <Typography.Text strong className='text-black-1'>*/}
        {/*                Q.PEAK DUO*/}
        {/*            </Typography.Text>*/}
        {/*            <Typography.Text style={{ fontSize: '12px' }} className='text-black-1'>*/}
        {/*                MLG-10 300W*/}
        {/*            </Typography.Text>*/}
        {/*        </Flex>*/}
        {/*        <Image src={down_arrow} preview={false} width={20} alt='down_arrow' />*/}
        {/*    </Flex>*/}
        {/*</Flex>*/}
        {/*<Flex justify='space-between' align='center' style={{ marginTop:'30px' }}>*/}
        {/*    <Image preview={false} width={45} src={grid_add} />*/}
        {/*    <Image preview={false} width={45} src={grid_drag} />*/}
        {/*    <Image preview={false} width={45} src={grid_delete} />*/}
        {/*    <Image preview={false} width={45} src={grid_copy} />*/}
        {/*</Flex>*/}
    </Card>
        </div>
  )
}

export default SegmentDetail