import {POLYGONS, VARS} from "../Global/variables";
import {sceneManager} from "../SetUps/SceneManager";
import {COLORS} from "../Global/materials";
import {removeObstacleDragControls, setObstacleDragControls} from "./DragControls";

export function deleteObstacle(segmentIndex, obstacleIndex) {
    let selectedSegment = POLYGONS[segmentIndex]
    let selectedObs = selectedSegment.obstructions[obstacleIndex];
    sceneManager.scene.remove(selectedObs.mesh);
    selectedObs.mesh.geometry.dispose();
    selectedObs.mesh.material.dispose();
    selectedSegment.obstructions.splice(obstacleIndex, 1);
    removeObstacleDragControls();
}

export function selectObstacle(selectedIndices,index, setSelectedIndices) {
    const { segmentIndex, obstacleIndex } = selectedIndices;
    let selectedObs = POLYGONS[segmentIndex].obstructions[index].mesh;
    if(obstacleIndex === index){
        setSelectedIndices({segmentIndex:segmentIndex,obstacleIndex: null})
        deselectObstacle(selectedObs)
    }else {
        setSelectedIndices({segmentIndex:segmentIndex,obstacleIndex:index})
        if(obstacleIndex !== null && POLYGONS[segmentIndex].obstructions.length >= obstacleIndex){
         deselectObstacle(POLYGONS[selectedIndices.segmentIndex].obstructions[obstacleIndex].mesh)
        }
        VARS.selectedObs = {originalColor:selectedObs.material.color.clone() }
        selectedObs.material.color.set(COLORS.yellow);
        setObstacleDragControls(selectedObs)
    }

}

export function deselectObstacle(selectedObs) {
    selectedObs.material.color.set(VARS.selectedObs.originalColor);
    removeObstacleDragControls();
}
