import React, { useState } from 'react';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { Modal } from 'antd';

const { confirm } = Modal;

const DeleteConfirmation = ({ onConfirm, onCancel }) => {
    const showDeleteConfirm = () => {
        confirm({
            title: 'Are you sure you want to delete this item?',
            icon: <ExclamationCircleFilled />,
            content: 'This action cannot be undone.',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk: () => {
                onConfirm(); // Invoke the onConfirm callback when the user clicks "Yes"
            },
            onCancel: () => {
                onCancel(); // Invoke the onCancel callback when the user clicks "No" or closes the dialog
            },
        });
    };

    return (
        <div>
            {showDeleteConfirm()}
        </div>
    );
};

export default DeleteConfirmation;
