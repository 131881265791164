
export const VARS ={
    Current_Project : null,
    selectedObs : {},
    segDrag : null,
    obsDrag : null,
    measurementLabelCounter : 1,
    setbackLabelCounter : 1,
    keyListener : null,
    mapTile : null,
    Meshes : []
}
export const DXF_VARS ={
    isVerticalFlipped :false,
    rotationAngle: null,
    vectorU : null,
    vectorV: null,
    origin : null,
    dormerData: [],
}
export const DrawingModes = {
    SEGMENT: 'SEGMENT',
    OBSTACLE: 'OBSTACLE',
    RULER: 'RULER',
    PANEL: 'PANEL',
    NONE: 'NONE',
};

export const ObstacleTypes = {
    RECTANGLE: 'RECTANGLE',
    CIRCLE: 'CIRCLE',
};

export const DrawingGlobals ={
    intersectionPoints:[],
    lineMeshes:[],
    verticesMesh:[],
}
export const LABELS ={
    measurements : [],
    angle : null,
}
export let POLYGONS =[];
export let VERTICES =[];
export let HISTORY = [];


