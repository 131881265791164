import * as THREE from "three";
import {DXF_VARS} from "../../Global/variables";

export function projectSegmentTo2D(polygon3D) {
    let sortedPolygon = sortPolygonByHighestPoint(polygon3D)

    const pointA = sortedPolygon[0]
    const pointB = sortedPolygon[1]
    const pointC = sortedPolygon[2]

    const edge1 = new THREE.Vector3().subVectors(pointB, pointA);
    const edge2 = new THREE.Vector3().subVectors(pointC, pointA);
    const normal = new THREE.Vector3().crossVectors(edge1, edge2).normalize();

    let u = new THREE.Vector3(1, 0, 0);
    u.projectOnPlane(normal).normalize();
    const v = new THREE.Vector3().crossVectors(normal, u).normalize();

    const polygon2D = sortedPolygon.map(point => {
        const x = point.dot(u);
        const y = point.dot(v);
        return new THREE.Vector2(x, y);
    });

    DXF_VARS.vectorU = u;
    DXF_VARS.vectorV = v;
    DXF_VARS.origin = polygon2D[0]
    return resetPolygonPoints(polygon2D)
}

export function sortPolygonByHighestPoint(polygon3D) {
    let highestZIndex = 0;
    for (let i = 1; i < polygon3D.length; i++) {
        if (polygon3D[i].z > polygon3D[highestZIndex].z) {
            highestZIndex = i;
        }
    }

    let nextIndex = (highestZIndex === 0) ? polygon3D.length - 1 : highestZIndex - 1;
    let prevIndex = (highestZIndex === polygon3D.length - 1) ? 0 : highestZIndex + 1;
    let direction = (polygon3D[prevIndex].z > polygon3D[nextIndex].z) ? 1 : -1;

    let rearrangedArray = [];
    let currentIndex = highestZIndex;
    while (rearrangedArray.length < polygon3D.length) {
        let point = polygon3D[currentIndex]
        rearrangedArray.push(new THREE.Vector3(point.x, point.y, point.z));
        currentIndex = (currentIndex + direction + polygon3D.length) % polygon3D.length;
    }

    rearrangedArray.push(rearrangedArray[0])
    return rearrangedArray;
}

export function resetPolygonPoints(polygon) {
    //  we save the referencePoint as DXF_VARS.origin to get obstacle position with respect to this
    const referencePoint = new THREE.Vector3(polygon[0].x, polygon[0].y, polygon[0].z);
    const resetPolygonVertices = polygon.map(point => {
        return new THREE.Vector3(point.x , point.y, point.z).sub(referencePoint);

    });
    let rotatedPolygonVertices = alignPolygonHorizontally(resetPolygonVertices)
    yFlipPolygon(rotatedPolygonVertices)

    return rotatedPolygonVertices
}

export function alignPolygonHorizontally(polygonVertices) {
    let farthestPointIndex = 1;

    const rotateDownward = polygonVertices[farthestPointIndex].y > polygonVertices[0].y;

    let lineAngle = Math.atan2(
        polygonVertices[farthestPointIndex].y - polygonVertices[0].y,
        polygonVertices[farthestPointIndex].x - polygonVertices[0].x
    );

    if (rotateDownward) {
        lineAngle += Math.PI;
    } else {
        lineAngle -= Math.PI;
    }
    DXF_VARS.rotationAngle = lineAngle;
    return polygonVertices.map(point => {
        const rotatedX = Math.cos(-lineAngle) * point.x - Math.sin(-lineAngle) * point.y;
        const rotatedY = Math.sin(-lineAngle) * point.x + Math.cos(-lineAngle) * point.y;
        return new THREE.Vector3(rotatedX, rotatedY, point.z);
    });

}

export function yFlipPolygon(rotatedPolygonVertices) {
    let isDiagonalHigher = false;
    for (let i = 2; i < rotatedPolygonVertices.length; i++) {
        if (rotatedPolygonVertices[i].y > rotatedPolygonVertices[0].y) {
            isDiagonalHigher = true;
            break;
        }
    }
    if (isDiagonalHigher) {
        rotatedPolygonVertices.forEach(point => {
            point.y = -point.y;
        });
        DXF_VARS.isVerticalFlipped = true;
    } else {
        DXF_VARS.isVerticalFlipped = false
    }
}