import {COLORS, LINES, OBSTACLE} from "../Global/materials";
import * as THREE from 'three'
import {ObstacleTypes, POLYGONS} from "../Global/variables";
import {addExtrude} from "./Modifications";
import {createCirGeo, createRecGeo} from "./Shapes";

class ObstacleManager {
    constructor(scene,camera,obstacleType,stopDrawing,updateObstacleList,selectedIndices) {
        this.scene = scene;
        this.camera = camera;
        this.startPoint = null;
        this.endPoint = null;
        this.plane = new THREE.Plane();
        this.box = OBSTACLE.box;
        this.circle = OBSTACLE.circle;
        this.type = obstacleType;
        this.isDrawingStarted = false;
        this.rayCaster = new THREE.Raycaster();
        this.stopDrawing = stopDrawing;
        this.updateObstacleList = updateObstacleList;
        if (typeof selectedIndices === 'object' && selectedIndices !== null) {
            this.selectedSegment = selectedIndices.segmentIndex;
        } else {

            this.selectedSegment = selectedIndices;
        }
    }
    handleMouseDown(point){
        if(!this.isDrawingStarted){
            this.startPoint = point;
            this.isDrawingStarted= true;
            this.createSegmentPlane();
        }else{
            switch (this.type) {
                case ObstacleTypes.RECTANGLE:
                    this.box.visible = false;
                    this.completeShape(this.points,0,COLORS.maroon,"rectangle")
                    break;
                case ObstacleTypes.CIRCLE:
                    this.circle.visible = false;
                    this.completeShape(this.points,0,COLORS.orange,"circle")
                    break;
            }
            this.isDrawingStarted =false;
            this.stopDrawing();
        }
    }
    handleMouseMove(point){
        if(this.isDrawingStarted){
            this.endPoint = point;
            switch (this.type) {
                case ObstacleTypes.RECTANGLE:
                    this.box.visible = true;
                    this.drawRectangle();
                    break;
                case ObstacleTypes.CIRCLE:
                    this.circle.visible=true;
                    this.drawCircle();
                    break;
            }
        }
    }
    createSegmentPlane(){
        let gp = POLYGONS[this.selectedSegment].shape.geometry.attributes.position;
        let p1 = new THREE.Vector3().fromBufferAttribute(gp, 0);
        let p2 = new THREE.Vector3().fromBufferAttribute(gp, 1);
        let p3 = new THREE.Vector3().fromBufferAttribute(gp, 2);
        this.plane.setFromCoplanarPoints(p1, p2, p3);
    }
    drawRectangle(){
       let {points,g} = createRecGeo(this.startPoint,this.endPoint,this.plane,this.rayCaster,this.camera,this.box);
        const center = new THREE.Vector3();
        g.computeBoundingBox();
        g.boundingBox.getCenter(center);
        this.box.geometry.dispose();
        this.box.geometry = g;
        center.normalize();
        this.box.geometry.attributes.position.needsUpdate = true;
        this.box.renderOrder=1;
        this.points = points;
    }
    drawCircle(){
        let {points,geo}= createCirGeo(this.startPoint,this.endPoint,this.plane,this.rayCaster,this.camera)
        const line = new THREE.Line(geo, LINES.orangeMaterial.clone());
        this.circle.geometry.dispose();
        this.circle.geometry = line.geometry;
        this.circle.material = line.material;
        this.points = points;
    }
    completeShape(points,height,color,type) {
        const shapePoints = points.map(point => new THREE.Vector2(point.x, point.y));
        const shape = new THREE.Shape(shapePoints);
        let extrudeMesh= addExtrude(points, shape,height,color, this.scene)
        this.addObstacle(type,shape,points,extrudeMesh,height)
    }
    addObstacle(type,shape,points,extrudeMesh,height) {
        let obs = {
        "type" : type,
        "polyShape": shape,
        "cords": points,
        "mesh": extrudeMesh,
        "height": height
         }
        POLYGONS[this.selectedSegment].obstructions.push(obs);
        let uiData = {type: type, height: height}
        this.updateObstacleList( { newObstacle: uiData, segmentIndex: this.selectedSegment });
    }
}

export default ObstacleManager;
