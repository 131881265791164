export const designerThemeLight = {
    token:{
        colorBgContainer:'#fff',
        borderRadius: 0,
    },
    components: {
        Layout: {
          headerBg: "#fff",
          headerPadding:'0 20px',
          headerHeight:40,
        },
        Button: {
          defaultBg: "#f8f8f8",
          defaultHoverBg: "#16169d",
        },
        InputNumber: {
            borderRadius: 0,
            fontWeight: 600,
        },
        Collapse : {
            borderRadiusLG: 0,
            headerBg:'#fff',
            headerPadding: '6px 9px',
        },
        Card : {
            borderRadiusLG: 0,
            fontSize: '16px'

        }
    
    }
}
export const designerThemeDark = {
    token:{
        colorBgContainer:'#141416',
        borderRadius: 0,
    },
    components: {
        Layout: {
          headerBg: "#141416",
          headerPadding: '0 20px',
          headerHeight:40,
        },
        Select: {
            colorBorder: '#525252',
            colorText:'#CDCDCD'
        },
        Button: {
          defaultBg: "#141416",
          defaultHoverBg: "#16169d",
          colorText:'#CDCDCD',
          defaultBorder: '#525252',
          primaryShadow: 'none',
        },
        InputNumber: {
            borderRadius: 0,
            fontWeight: 600,
            colorBgContainer:'#141416',
            colorBorder: '#525252',
            colorText:'#CDCDCD'
        },
        Collapse : {
            borderRadiusLG: 0,
            headerBg: '#141416',
            contentBg: '#141416',
            headerPadding: '6px 9px',
            colorBorder: '#525252',
        },
        Card : {
            borderRadiusLG: 0,
            headerBg: '#141416',
            contentBg: '#141416',
            colorBorderSecondary: '#525252',
            colorText:'#CDCDCD',
            fontSize: '16px',
            headerFontSize: '16px',

        },
        Typography: {
            colorText: '#f4f4f4',
        },
        Form: {
            labelColor: '#f4f4f4',
        }

    
    }
}