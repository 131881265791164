import {Button, Flex, Popconfirm, message} from "antd";
import React, {useEffect, useState} from "react";
import photo from "../assets/photo.svg";
import download from "../assets/export.svg";
import scale from "../assets/scale.svg";
import magic from "../assets/magic.svg";
import camera from '../assets/camera.svg'
import IconButton from '../components/IconButton'
import PhotoDrawer from "./PhotoDrawer";
import {downloadModel} from "../helpers/API";
import {useParams} from "react-router";
import {deleteAllSegments} from "../ThreeJs/Segments/Delete";
import {useRecoilState, useResetRecoilState} from "recoil";
import {magicToggleState, polygonsState, selectedIndicesState} from "../ThreeJs/ReactStates/states";
import {DrawingModes} from "../ThreeJs/Global/variables";
import {
    selectSegment,
    showSelectedSegmentLabel,
    toggleSegmentLabels
} from "../ThreeJs/Segments/Functions";

const ToolHeader = ({
        theme,
        currentProject,
        polygonUpdate,
        isLoading,
        handleUpdatePolygon,
        handleGenerateDxf,
        updateDrawingMode,
        currentDrawingMode
    }) => {
    const [isPhotoDrawerOpen, setPhotoDrawerOpen] = useState(false);
    const [selectedIndices, setSelectedIndices] = useRecoilState(selectedIndicesState)
    const resetPolygonsState = useResetRecoilState(polygonsState);
    const [magicToggle, setMagicToggle] = useRecoilState(magicToggleState)

    const params = useParams()
    const projectId = params?.designID
    const token = params?.token

    const togglePhotoDrawer = () => {
        setPhotoDrawerOpen(!isPhotoDrawerOpen);
    };

    const handleMagicToggle = () => {
        setMagicToggle((prev) => !prev)
    };
    useEffect(() => {
        if (magicToggle) {
            selectSegment(selectedIndices, selectedIndices.segmentIndex, setSelectedIndices, null);
            toggleSegmentLabels(true);
        } else {
            toggleSegmentLabels(false);
            if (selectedIndices.segmentIndex != null) {
                showSelectedSegmentLabel(selectedIndices.segmentIndex)
            }
        }

    }, [magicToggle]);
    useEffect(() => {
        if (selectedIndices.segmentIndex !== null && magicToggle) {
            setMagicToggle((prev) => !prev);
        }
    }, [selectedIndices.segmentIndex]);

    const closePhotoDrawer = () => {
        setPhotoDrawerOpen(false);
    };

    const handleDownloadModal = () => {
        if (currentProject && currentProject.model) {
            downloadModel(token, projectId).then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.download = projectId + '.zip';
                document.body.appendChild(link);
                link.click();
                window.URL.revokeObjectURL(url);
            }).catch((error) => {
                console.log(error)
            })
        } else {
            message.error("Error: Model URL is not valid");
        }
    };

    const handleScreenshot = () => {
        if (window.captureScreenshot) {
            window.captureScreenshot();
        } else {
            console.error("captureScreenshot function is not available");
        }
    };

    const handleDeleteAll = () => {
        deleteAllSegments()
        setSelectedIndices({segmentIndex: null, obstacleIndex: null});
        resetPolygonsState();
    }

    const handleRulerClick = () => {
        if (currentDrawingMode === DrawingModes.RULER) {
            updateDrawingMode(DrawingModes.NONE)
        } else if (currentDrawingMode === DrawingModes.NONE) {
            updateDrawingMode(DrawingModes.RULER)
        }
    }

    return (
        <Flex
            style={{
                background: theme ? "#141416" : "#ffffff",
                borderBottom: `1px solid ${!theme ? "#e1e1e1" : "#525252"}`,
                zIndex: "999"
            }}
            className="tool_header_container"
            justify="space-between"
            align="center"

        >
            <Flex gap={"large"} align="center">
                {/*<IconButton src={preview} className={`${theme && "gray-to-white"}`} />*/}
                {/*<IconButton src={forward} className={`${theme && "gray-to-white"}`} />*/}

                <IconButton
                    onClick={togglePhotoDrawer}
                    src={photo}
                    className={`${theme && "gray-to-white"}`}
                />
                {/*<Popconfirm*/}
                {/*    title="Do you want to download the model file?"*/}
                {/*    onConfirm={handleDownloadModal}*/}
                {/*    okText="Yes"*/}
                {/*    cancelText="No"*/}
                {/*>*/}
                {/*    <IconButton*/}
                {/*        src={download}*/}
                {/*        className={`${theme && "gray-to-white"}`}*/}
                {/*    />*/}
                {/*</Popconfirm>*/}

                <Popconfirm
                    title="Are you sure you want to delete all segments?"
                    onConfirm={handleDeleteAll}
                    okText="Yes"
                    cancelText="No"
                >
                    <Button
                        type="default"
                        danger
                        loading={isLoading}
                        size="middle"

                    >
                        <span style={{fontWeight: "550"}}>Delete All Segments</span>

                    </Button>
                </Popconfirm>

            </Flex>
            <Flex gap={"large"} align="center">
                {/*<Icon src={circleArrow} className={`${theme && "black-white"}`} />*/}
                {/*<InputNumber size="small" defaultValue={"177.4"} />*/}
                {/*<Icon src={DT} className={`${theme && "black-white"}`} />*/}
                {/*<InputNumber size="small" defaultValue={"47.4"} />*/}
                {/*<IconButton src={ruler} className={`${theme && "gray-to-white"}`} />*/}

                <IconButton
                    src={scale}
                    onClick={handleRulerClick}
                    className={`${
                        currentDrawingMode === DrawingModes.RULER
                            ? (theme ? 'gray-to-blue' : 'gray-to-blue')
                            : (theme ? 'gray-to-white': '')
                    }`}

                />

                <IconButton src={magic} className={`${
                    magicToggle
                        ? (theme ? 'gray-to-blue' : 'gray-to-blue')
                        : (theme ? 'gray-to-white': '')
                }`}
                            onClick={handleMagicToggle}/>
                <Popconfirm
                    title="Do you want to capture the screenshot of modal?"
                    onConfirm={handleScreenshot}
                    okText="Yes"
                    cancelText="No"
                >
                    <IconButton src={camera} className={`${theme && "gray-to-white"}`}/>
                </Popconfirm>
                <Button
                    loading={isLoading}
                    size="middle"
                    onClick={handleGenerateDxf}
                    default
                >
                    Generate DXF
                </Button>
                <Popconfirm
                    title="Do you want to save the current changes?"
                    onConfirm={polygonUpdate}
                    okText="Yes"
                    cancelText="No"
                >
                    <Button
                        loading={isLoading}
                        size="default"
                        default
                        onClick={handleUpdatePolygon}
                    >
                        Save Changes
                    </Button>
                </Popconfirm>
            </Flex>
            <PhotoDrawer
                isPhotoDrawerOpen={isPhotoDrawerOpen}
                closePhotoDrawer={closePhotoDrawer}
            />
        </Flex>
    );
};

export default ToolHeader;
