import {point3d,Units, DxfWriter} from "@tarikjabiri/dxf";
import {add2dShapes, drawShapeToBlock, setLayerColors} from "./DxfDraw";
import {calculateBlockDimensions} from "./DxfCalculations";



export const generateDxf = (dxfData) => {
    const dxf = new DxfWriter();
    dxf.setUnits(Units.Unitless);

    // TODO: add layers later after fixing error
    // setLayerColors(dxf);

    const sceneBlock = dxf.addBlock(`ThreeScene`);
    const rowBlock = dxf.addBlock(`RowBlock`);

    let polygonPlacementX = 0;
    let lastMax = 0;

    // function to draw 3D model shapes
    dxfData.forEach((item) => {
        drawShapeToBlock(dxf,item.polygon, sceneBlock, {
            addName: true,
        }, item.label)
        item.obstruction.forEach((obs, obsIndex) => {
            drawShapeToBlock(dxf, obs.polygon, sceneBlock, {}, obsIndex)
        });

    });

    // function to draw separate 2D shapes
    dxfData.forEach((item, index) => {
        const polygonBlock = dxf.addBlock(`PolygonBlock_${index}`);
        const polyData = add2dShapes(dxf, item, polygonBlock,index)
        polygonPlacementX = index !== 0 ? polygonPlacementX + (20 * 12) + lastMax + (-polyData.minX) : polygonPlacementX;
        rowBlock.addInsert(polygonBlock.name, point3d(polygonPlacementX, 0, 0));
        lastMax = polyData.maxX;
    });


    const sceneDimensions = calculateBlockDimensions(sceneBlock);
    const rowBlockPlacementY = sceneDimensions.minY - 200;
    dxf.addInsert(sceneBlock.name, point3d(0, 0, 0));
    dxf.addInsert(rowBlock.name, point3d(sceneDimensions.minX, rowBlockPlacementY, 0));


    print(dxf);

};



function print(dxfInstance) {
    let $units = '$DIMALTU\n  70\n6\n  9\n';
    $units += '$DIMLUNIT\n  70\n4\n  9\n';
    $units += '$LUNITS\n  70\n4\n  9\n$ACADVER';

    let dxf_output = dxfInstance.stringify();
    dxf_output = dxf_output.replace('$ACADVER', $units);
    const element = document.createElement('a');
    let dxf_file = new Blob([dxf_output], {type: 'text/plain'});

    element.href = URL.createObjectURL(dxf_file);
    element.download = 'myFile.dxf';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
}
