import * as THREE from "three";

export function createRecGeo(startPoint, endPoint, facetPlane,rayCaster, camera) {
    if (startPoint.x === endPoint.x) {
        endPoint.x += Number.EPSILON;
    }

    if (startPoint.y === endPoint.y) {
        endPoint.y += Number.EPSILON;
    }

    const left = Math.min(startPoint.x, endPoint.x);
    const right = Math.max(startPoint.x, endPoint.x);
    const top = Math.min(startPoint.y, endPoint.y);
    const bottom = Math.max(startPoint.y, endPoint.y);
    const width = right - left;
    const height = bottom - top;

    let dx = startPoint.x - endPoint.x < 0 ? -1 : 1;
    let dy = startPoint.y - endPoint.y < 0 ? -1 : 1;

    // case 1 -x,+y, +x,-y
    let _vecTopLeft = new THREE.Vector3(startPoint.x, startPoint.y, 0);
    let _vecDownRight = new THREE.Vector3(endPoint.x, endPoint.y, 0);
    let _vecTopRight = new THREE.Vector3(
        startPoint.x - dx * width,
        startPoint.y,
        0
    );
    let _vecDownLeft = new THREE.Vector3(
        startPoint.x,
        startPoint.y - dy * height,
        0
    );

    if (dy === dx) {
        _vecTopLeft.set(startPoint.x, startPoint.y - dy * height, 0);
        _vecDownRight.set(startPoint.x - dx * width, startPoint.y, 0);
        _vecTopRight.set(endPoint.x, endPoint.y, 0);
        _vecDownLeft.set(startPoint.x, startPoint.y, 0);
    }

    // let worldStartPoint = _vecTopLeft.clone().applyMatrix4(camera.matrixWorldInverse);

    let topleft = new THREE.Vector3();
    let topright = new THREE.Vector3();
    let bottomleft = new THREE.Vector3();
    let bottomright = new THREE.Vector3();

    rayCaster.setFromCamera(_vecTopLeft, camera);
    rayCaster.ray.intersectPlane(facetPlane, topleft);
    rayCaster.setFromCamera(_vecTopRight, camera);
    rayCaster.ray.intersectPlane(facetPlane, topright);
    rayCaster.setFromCamera(_vecDownLeft, camera);
    rayCaster.ray.intersectPlane(facetPlane, bottomleft);
    rayCaster.setFromCamera(_vecDownRight, camera);
    rayCaster.ray.intersectPlane(facetPlane, bottomright);
    let points=[topleft,topright,bottomright,bottomleft,topleft];
    let g=  new THREE.BufferGeometry().setFromPoints([
        topleft,
        topright,
        bottomright,
        bottomleft,
        topleft,
    ]);

    return {points,g}
}

export function createCirGeo(startPoint,endPoint,facetPlane,rayCaster,camera) {
    const points = [];
    let centerX =startPoint.x;
    let centerY = startPoint.y;
    const radius = startPoint.distanceTo(endPoint)

    const deltaX = endPoint.x- centerX;
    const deltaY = endPoint.y - centerY;
    let dynamicRadius = Math.sqrt(deltaX * deltaX + deltaY * deltaY);
    dynamicRadius += radius;

    const aspectRatio = camera.aspect;
    let segments =32;
    for (let i = 0; i <= segments; i++) {
        const theta = (i / segments) * Math.PI * 2;
        const adjustedRadius = dynamicRadius / aspectRatio;
        const x = centerX + adjustedRadius * Math.cos(theta) ;
        const y = centerY + dynamicRadius  * Math.sin(theta);

        let temp = new THREE.Vector3(x, y, 0)

        let newpoint = new THREE.Vector3()
        rayCaster.setFromCamera(temp, camera);
        rayCaster.ray.intersectPlane(facetPlane, newpoint);

        points.push(newpoint);
    }
    points.push(points[0]);

    let geo= new THREE.BufferGeometry().setFromPoints(points);
    return {points,geo};

}