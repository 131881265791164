function windingNumber(point, vertices) {
    let wn = 0; // Winding number counter

    for (let i = 0; i < vertices.length; i++) {
        const v1 = vertices[i];
        const v2 = vertices[(i + 1) % vertices.length];

        if (v1.y <= point.y) {
            if (v2.y > point.y && isLeft(v1, v2, point) > 0) {
                wn++;
            }
        } else {
            if (v2.y <= point.y && isLeft(v1, v2, point) < 0) {
                wn--;
            }
        }
    }
    return wn;
}

// Helper function to determine if a point is left, on, or right of an infinite line
function isLeft(p0, p1, p2) {
    return (p1.x - p0.x) * (p2.y - p0.y) - (p2.x - p0.x) * (p1.y - p0.y);
}

// Function to check if one polygon is inside another
export function isPolygonInsidePolygon(innerVertices, outerVertices) {
    for (const vertex of innerVertices) {
        if (windingNumber(vertex, outerVertices) === 0) {
            return false;
        }
    }
    return true;
}
