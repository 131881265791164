import {point2d, point3d} from "@tarikjabiri/dxf";
import * as THREE from "three";


export function projectTo2DFeetInches(point){
   let x= point.x * 12 *3.281;
   let y = point.y * 12 * 3.281;
   return { point : point2d(x, y)};
}

export function inch2feet(value) {
   value= value*3.28;
   value = Math.abs(value);
   var feet = Math.floor(value);
   var inches = (Math.round((value - feet) * 12) );
   var result = feet + "'";
   if (inches > 0) {
      result += inches + '"';
   }
   return result;
}
export function findSmallestVertex(polygons) {
   let smallest_x = Number.MAX_SAFE_INTEGER;
   let smallest_y = Number.MAX_SAFE_INTEGER;
   polygons.forEach(polygon => {

      smallest_x = Math.min(smallest_x, (polygon.x));
      smallest_y = Math.min(smallest_y, (polygon.y));

   });

   return [smallest_x.toFixed(2), smallest_y.toFixed(2)];
}
export function findLargestVertex(polygons) {
   let largest_x = Number.MIN_SAFE_INTEGER;
   let largest_y = Number.MIN_SAFE_INTEGER;
   polygons.forEach(polygon => {
      largest_x = Math.max(largest_x, polygon.x);
      largest_y = Math.max(largest_y, (polygon.y));
   });

   return [largest_x.toFixed(2), largest_y.toFixed(2)];
}
export function calculateLengthWidth(data) {
   const smallestVertex = findSmallestVertex(data);
   const largestVertex = findLargestVertex(data);
   const length = ((largestVertex[0] - smallestVertex[0]) ).toFixed(2);
   const width = ((largestVertex[1] - smallestVertex[1]) ).toFixed(2);

   let w = inch2feet(length);
   let l = inch2feet(width);

   return { l, w };
}

export function calculateBlockDimensions(block) {
   let minX = Number.MAX_SAFE_INTEGER;
   let maxX = Number.MIN_SAFE_INTEGER;
   let minY = Number.MAX_SAFE_INTEGER;
   let maxY = Number.MIN_SAFE_INTEGER;

   if (block.entities && Array.isArray(block.entities)) {

      block.entities.forEach(entity => {
         if (entity.vertices && Array.isArray(entity.vertices)) {
            entity.vertices.forEach(vertex => {
               minX = Math.min(minX, vertex.point.x);
               maxX = Math.max(maxX, vertex.point.x);
               minY = Math.min(minY, vertex.point.y);
               maxY = Math.max(maxY, vertex.point.y);
            });
         }
      });
   }
   const width = maxX - minX;
   const height = maxY - minY;
   return { minX, maxX, minY, maxY, width, height};
}

export function getPolygonDimensions(polygonVertices) {

   let minX = polygonVertices[0].point.x;
   let minY = polygonVertices[0].point.y;
   let maxX = polygonVertices[0].point.x;
   let maxY = polygonVertices[0].point.y;

   // Find the minimum and maximum coordinates along X and Y axes
   polygonVertices.forEach(point => {
      minX = Math.min(minX, point.point.x);
      minY = Math.min(minY, point.point.y);
      maxX = Math.max(maxX, point.point.x);
      maxY = Math.max(maxY, point.point.y);
   });

   const width = maxX - minX;
   const height = maxY - minY;

   return { width, height, minY, maxY,maxX,minX };
}

export function calculateMidpoint(point1, point2) {
   return point3d((point1.x + point2.x) / 2, (point1.y + point2.y) / 2);
}

export const calculateDistance = (point1, point2) => {
   let dx = point2.x - point1.x;
   let dy = point2.y - point1.y;
   return Math.sqrt(dx * dx + dy * dy);
};

export function calculateCenter(polygon3D) {
   const center = new THREE.Vector2();
   polygon3D.forEach((point) => {
      center.add(point.point);
   });
   return point3d(center.x / polygon3D.length, center.y / polygon3D.length, 0);
}