import {getPolygonDimensions, projectTo2DFeetInches} from "./DxfCalculations";
import {ObstacleDetailTable, SegmentDetailsTable, TotalObsTable} from "./DxfTables";
import {addMeasurementLable, addNameLabel, addPointLabels, SegmentDetailLabels} from "./DxfLabels";
import {Colors, LWPolylineFlags, point2d, point3d} from "@tarikjabiri/dxf";


export function add2dShapes(dxf, item, Block,i) {

    let obsLabelIndex=0;
    const polygon2DInches = drawShapeToBlock(dxf,item.rotated_polygon, Block, {
        addPointLabel: true,
        addMeasurementLabel: true,
        addCircle: true,
        shape : "polygon"
    }, i)

    item.dormerData.forEach((data,index) =>{
         drawShapeToBlock(dxf,data, Block, {
            isObs: true,
            addName: true,
            addCircle: true,
            shape: "dormer"
        },obsLabelIndex,i);

        obsLabelIndex++;
    })

    if (item.obstruction.length > 0) {
        item.rotated_obstruction.forEach((obs, obsIndex) => {
            drawShapeToBlock(dxf,obs.polygon, Block, {
                isObs: true,
                addName: true,
                shape: "obstacle"
            }, obsLabelIndex,i);
            obsLabelIndex++;
        });

    }

    let polyData = getPolygonDimensions(polygon2DInches)
    drawTables(item, item.rotated_polygon,item.rotated_obstruction,item.dormerData, polyData, Block)
    return polyData;
}

export function drawTables(item, reset2dPolygon, obsPoints,dormerPoints, polyData, Block) {
    let cellHeight = 12;
    let tableStartY = polyData.minY - 50;
    let tableStartX = polyData.minX;
    let segmentColumns = [23, 25, 25]// [POI,X,X]//
    let xSpace = SegmentDetailsTable(item.label, reset2dPolygon, tableStartX, tableStartY, cellHeight, segmentColumns, Block)
    SegmentDetailLabels(Block, tableStartX, polyData.maxY + 40, item.detail, item.label)
    xSpace += tableStartX;

    if (item.obstruction.length > 0 || dormerPoints.length >0 ) {

        const obstacleColumns = [55, 52, 42, 30, 30]; //[Obstacles, len/Dia, Width, X, Y]//
        let ySpace = ObstacleDetailTable(item.label, [item.obstruction, obsPoints,dormerPoints], xSpace, tableStartY, cellHeight, obstacleColumns, Block)
        ySpace = tableStartY - ySpace;

        if(item.obstruction.length > 0){
            const cellWidths = [88, 43]; //[NAME, COUNT]
            TotalObsTable(item.obstruction, xSpace, ySpace, cellHeight, cellWidths, Block)
        }
    }

}

export function drawShapeToBlock(dxf,polygon2d, Block, options = {},index,segmentIndex,buttonFlag) {
    const {
        addPointLabel = false,
        addMeasurementLabel = false,
        addCircle = false,
        isObs = false,
        addName = false,
        shape = "polygon"
    } = options;

    let inchesPoints;
    inchesPoints  = polygon2d.map(point => projectTo2DFeetInches(point));


    // let Block =null;

    // if(shape === 'obstacle'){
    //     Block = dxf.addBlock(`ObstacleBlock_${index}_${segmentIndex}`);
    //     Block.setLayerName('l_obstacle')
    // }else if(shape ==='dormer'){
    //     Block = dxf.addBlock(`DormerBlock_${index}_${segmentIndex}`);
    //     Block.setLayerName('l_green')
    // }else{
    //     Block=parentBlock;
    // }

    Block.addLWPolyline(inchesPoints, {flags: LWPolylineFlags.Closed});


    if (addPointLabel) {
        addPointLabels(inchesPoints, Block);
    }

    if (addMeasurementLabel && isObs) {
        addMeasurementLable(polygon2d, inchesPoints, Block);
    } else if (addMeasurementLabel && !isObs) {
        addMeasurementLable(polygon2d, inchesPoints, Block);
    }

    if (addName) {
        addNameLabel(inchesPoints, Block, index, isObs)
    }

    if (addCircle) {
        let circleBlock = setCircleBlocks(dxf,shape, index,segmentIndex,inchesPoints)
        Block.addInsert(circleBlock.name,point3d(0,0,0));

    }
    // parentBlock.addInsert(Block.name, point3d(0, 0, 0));
    return inchesPoints;
}

export function setLayerColors(dxf){
    // dxf.addLayer('l_green', 32 , 'ACAD_ISO11W600')
    // dxf.addLayer('l_red', Colors.Red, 'CONTINUOUS')
    // dxf.addLayer('l_cyan', Colors.Cyan, 'CONTINUOUS')
    //
    // dxf.addLayer('l_orange', 32, 'CONTINUOUS');
    // dxf.addLayer('l_magenta', Colors.Magenta , 'CONTINUOUS')
    // dxf.addLayer('l_blue', Colors.Blue, 'CONTINUOUS')
    // dxf.addLayer('l_black', Colors.Black, 'CONTINUOUS')
    // dxf.addLayer('l_seaBlue',  132, 'CONTINUOUS')
    // dxf.addLayer('l_obstacle',  232, 'CONTINUOUS')

}

function setCircleBlocks(dxf,shape, index,segmentIndex,inchesPoints){
    const circleBlock = dxf.addBlock(`CircleBlock_${index}_${segmentIndex}`);
    if(shape === 'dormer'){
        circleBlock.setLayerName('l_seaBlue')
        circleBlock.addCircle(point2d(inchesPoints[0].point.x, inchesPoints[0].point.y),1.2);
    }else{
        circleBlock.setLayerName('l_cyan')
        circleBlock.addCircle(point2d(inchesPoints[0].point.x, inchesPoints[0].point.y),5);
    }
    return circleBlock;
}