import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {checkUser, getDesign} from "../helpers/API";
import {DEV_MOD} from "../helpers/CONSTANT";
import {generateDxf} from "../ThreeJs/Files/Dxf/DxfGenerator";
import LoadingScreen from "../components/LoadingScreen";
import NotFound from "../components/errorsscreens/NotFound";

const GenerateDxf = () => {
    const [currentDesign, setCurrentDesign] = useState(null);
    const {token, designID} = useParams();
    const [informationChecked, setInformationChecked] = useState(null);
    const [isGenerating, setIsGenerating] = useState(false);
    const [error, setError] = useState(null);
    const [currentUser, setCurrentUser] = useState(null);

    useEffect(() => {
        if (DEV_MOD) {
            setInformationChecked("found");
            return; // Exit early if in development mode
        }
        if (token && designID) {
            verifyUser();
        } else {
            setInformationChecked("unauthenticated");
        }
    }, [token, designID]);

    const verifyUser = async () => {
        try {
            const res = await checkUser(token);
            if (res.status === 200) {
                setCurrentUser(res.data);
                await checkModelInfo(); // Await for model info check
            } else {
                setInformationChecked("unauthenticated");
            }
        } catch (err) {
            console.error("User verification failed:", err);
            setInformationChecked("unauthenticated");
            setError("Failed to verify user.");
        }
    };

    const checkModelInfo = async () => {
        try {
            const res = await getDesign(token, designID);
            if (res.status === 200) {
                setCurrentDesign(res.data.data);
                setInformationChecked("found");
                setIsGenerating(true); // Start DXF generation
            } else {
                setInformationChecked("not-found");
            }
        } catch (err) {
            console.error("Failed to retrieve design information:", err);
            setInformationChecked("not-found");
            setError("Failed to retrieve design information.");
        }
    };

    const handleGenerateDxf = async () => {
        if (!currentDesign) return; // Ensure currentDesign is available

        console.log("Starting DXF generation...");
        let polygon;

        try {
            polygon = JSON.parse(currentDesign.model_data);
            const result = await generateDxf(polygon); // Generate the DXF file

            // Notify the parent window that DXF generation is complete
            if (window.opener) {
                window.opener.postMessage('dxfGenerated', '*');
            }
        } catch (err) {
            console.error("Failed to generate DXF:", err);
            setError("DXF generation failed.");
        } finally {
            setIsGenerating(false); // Hide loader when done, regardless of success or failure
        }
    };

    // Call DXF generation when the component renders based on state
    useEffect(() => {
        if (isGenerating) {
            handleGenerateDxf();
        }
    }, [isGenerating, currentDesign]); // Ensure to run when currentDesign is set

    return (
        <>
            {informationChecked == null ? (
                <LoadingScreen informationChecked={informationChecked}/>
            ) : informationChecked === "unauthenticated" ? (
                <NotFound type={"token"}/>
            ) : informationChecked === "not-found" ? (
                <NotFound type={"design"}/>
            ) : (
                <LoadingScreen type={7}/> // Show loader during DXF generation
            )
            }
        </>
    );
};

export default GenerateDxf;
