import { Flex, Form, InputNumber, Select ,Typography} from "antd";
import React, {useEffect} from "react";
import {useState} from "react";
import {useRecoilState, useRecoilValue, useSetRecoilState} from "recoil";
import {selectedObstacleHeight, polygonsState, selectedIndicesState} from "../ThreeJs/ReactStates/states";
import {changeObstacleHeight} from "../ThreeJs/Obstacles/Modifications";
import {updateObstacleHeightSelector} from "../ThreeJs/ReactStates/updates";
import {themeState} from "../lib/atom";

const ObstacleDetail = ({ }) => {
    const [position, setPosition] = useState("Zaxis");
    const selectedIndices = useRecoilValue(selectedIndicesState);
    const polygons = useRecoilValue(polygonsState);
    const [height,setHeight] = useRecoilState(selectedObstacleHeight);
    const setObstacleHeight = useSetRecoilState(updateObstacleHeightSelector)
    const theme = useRecoilState(themeState)[0];

    const handleHeightChange =  (value) =>{
     setHeight(value);
     changeObstacleHeight(selectedIndices.segmentIndex,selectedIndices.obstacleIndex,value);
     setObstacleHeight(value);
    };
    
    useEffect(() => {
        if (selectedIndices.segmentIndex !== null && selectedIndices.obstacleIndex !== null) {
            const segment = polygons[selectedIndices.segmentIndex];
            const selectedObstacle = segment.obstructions[selectedIndices.obstacleIndex];
            let height= selectedObstacle ? selectedObstacle.height : null;
            setHeight(height)
        }
    }, [selectedIndices.obstacleIndex]);

  return (
    <Form layout="vertical" >
      <Flex gap={"small"}>
        <Flex flex={1} className={`${!theme ? 'text-light': 'text-black'}`}>
          <Form.Item label="Postion" >
            <Select
              options={[
                {
                  label: "Zaxis",
                  value: "Zaxis",
                },
                {
                  label: "Perpendicular",
                  value: "Perpendicular",
                },
              ]}
              style={{ width: "140px" }}
              defaultValue={position}
              // onChange={handlePositionChange}
            />
          </Form.Item>
        </Flex>
        <Flex>
          <Form.Item label="Height">
            <InputNumber className="input_number"
                         min={0}
                         step={0.1}
                         value={height}
                         onChange={handleHeightChange}/>
          </Form.Item>
        </Flex>
        {/*<Flex>*/}
        {/*  <Form.Item label="Radius" >*/}
        {/*    <InputNumber className="input_number" disabled={true}*/}
        {/*     />*/}
        {/*  </Form.Item>*/}
        {/*</Flex>*/}
      </Flex>
    </Form>
  );
};

export default ObstacleDetail;
