import {DrawingGlobals, LABELS} from "../../Global/variables";
import {COLORS} from "../../Global/materials";
import {showAngle} from "./SnapTo90";

export function getSnapPoint(snapPoint,snapVertex,snap90,point){
    snapPoint = findClosestSnapPoint(point,snapVertex,snap90);
    if(!snapPoint){
        snapPoint = point;
    }
    else if (snapVertex && snapPoint === snapVertex.position) {
        snapVertex.material.opacity = 1;

    } else if (snapPoint === snap90) {
        DrawingGlobals.lineMeshes.slice(-1)[0].material.color.setHex(COLORS.green);
    }
    if((snap90  && snap90 !== snapPoint) || !snap90){
        snap90 = null;
        LABELS.angle.visible = false;
        DrawingGlobals.lineMeshes.slice(-1)[0].material.color.setHex(COLORS.red);
    }
    if(snapVertex && (snapVertex.position !== snapPoint)){
        snapVertex.material.opacity =0;
        snapVertex = null;
    }
    return snapPoint;
}
function findClosestSnapPoint( point,snapVertex,snap90) {
    let vertexSnap=null;
    if(snapVertex){
        vertexSnap = snapVertex.position;
    }
    if (!snap90 && vertexSnap) {
        return vertexSnap;
    } else if (snap90 && !vertexSnap) {
        return snap90;
    } else if (!snap90 && !vertexSnap) {
        return null;
    }
    const distances = {
        snap90: snap90.distanceTo(point),
        snapVertex: vertexSnap.distanceTo(point)
    };
    const closestSnapPoint = Object.keys(distances).reduce((minKey, key) => {
        return distances[key] < distances[minKey] ? key : minKey;
    });
    return closestSnapPoint === 'snap90' ? snap90 : vertexSnap;
}