
import {point3d, TextHorizontalAlignment, TextVerticalAlignment} from "@tarikjabiri/dxf";
import {calculateCenter, calculateDistance, calculateMidpoint, inch2feet} from "./DxfCalculations";

export function addNameLabel(polygon3D, Block, label, isObs) {
    const center = calculateCenter(polygon3D);
    const fontSize = isObs ? 4 : 12;
    const fontColor = isObs ? 10 : 40; //60
    label = isObs ? `P${label}` : label;
    const alignment = isObs ? {horizontal: TextHorizontalAlignment.Center, vertical: TextVerticalAlignment.Middle} :
        {horizontal: TextHorizontalAlignment.Center, vertical: TextVerticalAlignment.Middle};
    addLabel(Block, label, fontSize, fontColor, center, alignment);
}

export function addMeasurementLable(adjustedPoints, inchesPoints, Block) {
    for (let i = 0; i < adjustedPoints.length - 1; i++) {
        const segmentDistance = inch2feet(calculateDistance(adjustedPoints[i], adjustedPoints[i + 1]).toFixed(2));
        const midpoint = calculateMidpoint(inchesPoints[i].point, inchesPoints[i + 1].point);
        const fontSize = 10;
        const fontColor = 112;
        const alignment = {horizontal: TextHorizontalAlignment.Center, vertical: TextVerticalAlignment.Bottom};
        addLabel(Block, segmentDistance, fontSize, fontColor, midpoint, alignment);
    }
}

export function addPointLabels(points, Block) {
    points.forEach((point, index) => {
        const midpoint = point3d(points[index].point.x + 0.8, points[index].point.y + 0.8, 0)
        const fontSize = 9;
        const fontColor = 10;
        const alignment = {horizontal: TextHorizontalAlignment.Center, vertical: TextVerticalAlignment.Bottom};
        if (index !== points.length - 1) {
            addLabel(Block, index, fontSize, fontColor, midpoint, alignment);
        }
    });
}

export function SegmentDetailLabels(drawing, startX, startY, data, label) {
    let spaceY = startY;
    Object.entries(data).forEach(([key, value]) => {
        if (value != null) {
            const position = point3d(startX, spaceY);
            const fontSize = 12;
            const fontColor = 52;
            const alignment = {horizontal: TextHorizontalAlignment.Left, vertical: TextVerticalAlignment.Middle};
            addLabel(drawing, `${key}: ${value.toString()}`, fontSize, fontColor, position, alignment);
            spaceY += 19.5;
        }
    });
    const position = point3d(startX, spaceY + 2);
    const fontSize = 22;
    const fontColor = 40;
    const alignment = {horizontal: TextHorizontalAlignment.Left, vertical: TextVerticalAlignment.Middle};
    addLabel(drawing, `SEGMENT ${label}`, fontSize, fontColor, position, alignment);
}

function addLabel(container, text, fontSize, fontColor, position, alignment) {
    container.addText(position, fontSize, text, {
        rotation: 0,
        horizontalAlignment: alignment.horizontal,
        verticalAlignment: alignment.vertical,
        colorNumber: fontColor,
        secondAlignmentPoint: position,
    });
}
