import {DragControls} from "three/examples/jsm/controls/DragControls";
import { VARS} from "../Global/variables";
import {sceneManager} from "../SetUps/SceneManager";


export function setObstacleDragControls(obj) {
    removeObstacleDragControls();

    obj.userData.originalZ = obj.position.z;
    VARS.obsDrag = new DragControls([obj], sceneManager.camera, sceneManager.renderer.domElement);
    VARS.obsDrag.addEventListener('dragstart', onDragStart);
    VARS.obsDrag.addEventListener('drag', onDrag);
    VARS.obsDrag.addEventListener("dragend", (event) => dragEnd(event));
    return VARS.obsDrag;
}
const dragEnd = (event) => {
    sceneManager.controls.enabled = true;
    if(VARS.segDrag){
        VARS.segDrag.activate();
    }
}
function onDragStart(event) {
    sceneManager.controls.enabled = false;
    if(VARS.segDrag){
        VARS.segDrag.deactivate();
    }
    const draggedObject = event.object;
    draggedObject.userData.originalZ = draggedObject.position.z;
}

function onDrag(event) {
    const draggedObject = event.object;
    draggedObject.position.z = draggedObject.userData.originalZ;
}

export function removeObstacleDragControls() {
    if (VARS.obsDrag) {
        VARS.obsDrag.dispose();
        VARS.obsDrag=null;
    }
}