import React, {useEffect, useMemo, useRef, useState} from "react";
import {ModelManager} from "./SetUps/ModelManager";
import {downloadImage} from "../helpers/CONSTANT";
import {sceneManager} from "./SetUps/SceneManager";
import {MouseEventHandlers} from "./Events/MouseEventHandlers";
import {POLYGONS, VARS} from "./Global/variables";
import {useRecoilState, useRecoilValue, useSetRecoilState} from "recoil";
import {
    avaArea,
    editSetBacks,
    polygonsState,
    selectedIndicesState, selectedPanel, setBackInput,
} from "./ReactStates/states";
import {addObstaclesSelector, updatePolygonDetailsSelector} from "./ReactStates/updates";
import {loadDrawings} from "./DataUtils/LoadData";
import { updateSetBacksLabelsPosition} from "./Global/functions";
import {updateAvaArea, updateSetbackUI} from "./Segments/SetBacks/Functions";



export default function Main({setModelProgress, currentDesign,currentDrawingModeRef,updateDrawingMode
,obstacleTypeRef,setDeleteConfirmationVisible}) {

    const refContainer = useRef(null);
    const SceneManager = useMemo(() => sceneManager, []);
    const { scene, camera, renderer, controls,labelRenderer } = SceneManager.getSceneObjects();
    const modelMeshs = useMemo(() => new ModelManager(scene, setModelProgress, currentDesign), [scene],);
    const setPolygons = useSetRecoilState(polygonsState);
    const setObstacles = useSetRecoilState(addObstaclesSelector);
    const [selectedIndices, setSelectedIndices] = useRecoilState(selectedIndicesState);
    const setPolygonDetails = useSetRecoilState(updatePolygonDetailsSelector);
    const [inputValues, setInputValues] = useRecoilState(setBackInput);
    const [editSetback,setEditSetback] = useRecoilState(editSetBacks)
    const editSetbackRef = useRef(editSetback);
    const selectedIndicesRef = useRef(selectedIndices);
    const  setAvailableArea = useSetRecoilState(avaArea);
    const selectedPanels = useRecoilValue(selectedPanel);
    let  mouseEventHandlers =null;

    const captureScreenshot = () => {
        const canvas = renderer.domElement;
        requestAnimationFrame(() => {
            // Create composite canvas for the main scene
            const compositeCanvas = document.createElement("canvas");
            const compositeContext = compositeCanvas.getContext("2d");
            compositeCanvas.width = canvas.width;
            compositeCanvas.height = canvas.height;
            compositeContext.drawImage(canvas, 0, 0);

            const thumbnailCanvas = document.createElement("canvas");
            const thumbnailContext = thumbnailCanvas.getContext("2d");
            thumbnailCanvas.width = canvas.width;
            thumbnailCanvas.height = canvas.height;
            const thumbnailSize = 100;
            const thumbnailMargin = 30;
            const thumbnailX = thumbnailCanvas.width - thumbnailSize - thumbnailMargin;
            const thumbnailY = thumbnailCanvas.height - thumbnailSize - thumbnailMargin;
            thumbnailContext.drawImage(canvas, thumbnailX, thumbnailY, thumbnailSize, thumbnailSize);

            const finalCompositeCanvas = document.createElement("canvas");
            const finalCompositeContext = finalCompositeCanvas.getContext("2d");
            finalCompositeCanvas.width = canvas.width;
            finalCompositeCanvas.height = canvas.height;

            finalCompositeContext.drawImage(compositeCanvas, 0, 0);

            finalCompositeContext.drawImage(thumbnailCanvas, 0, 0);

            const imgData = canvas.toDataURL("image/jpeg", 1.0);

            const thumbnailImage = new Image();
            thumbnailImage.src = imgData;
            thumbnailImage.style.position = "fixed";
            thumbnailImage.style.width = thumbnailSize + "px";
            thumbnailImage.style.height = thumbnailSize + "px";
            thumbnailImage.style.borderRadius = 10 + "px";
            thumbnailImage.style.transition = "all 1s ease";
            thumbnailImage.style.border = "2px solid #1677ff";

            const thumbnailMarginRight = 30;
            const finalX = window.innerWidth - thumbnailSize - thumbnailMarginRight;
            const finalY = window.innerHeight - thumbnailSize - thumbnailMargin;
            thumbnailImage.style.top = thumbnailMargin + "px";
            thumbnailImage.style.right = thumbnailMargin + "px";
            document.body.appendChild(thumbnailImage);
            setTimeout(() => {
                thumbnailImage.style.top = finalY + "px";
                thumbnailImage.style.left = finalX + "px";
                setTimeout(() => {
                    document.body.removeChild(thumbnailImage);
                    downloadImage(imgData, "screenshot.jpg");
                }, 1000);
            }, 100);
        });
    };

    useEffect(() => {
      editSetbackRef.current = editSetback;
    }, [editSetback]);

    useEffect(() => {
        selectedIndicesRef.current = selectedIndices;
        setEditSetback(false);
    }, [selectedIndices]);

    useEffect(() => {
        VARS.Current_Project = currentDesign;
        refContainer.current && refContainer.current.appendChild(renderer.domElement);
        refContainer.current.appendChild(labelRenderer.domElement);
        loadDrawings(scene,setPolygons,setObstacles);
        VARS.measurementLabelCounter= POLYGONS.length-1;
        VARS.setbackLabelCounter = POLYGONS.length-1;


        const animate = () => {

            if(editSetbackRef.current ){
                let edges=POLYGONS[selectedIndicesRef.current.segmentIndex].setBacks.edgesData;
                const canvas = document.querySelector('canvas');
                let halfWidth = canvas.width / 2;
                let halfHeight = canvas.height / 2;
                edges.forEach((edge,index) => {
                    const label = document.getElementById(`setback-${index}`);
                    updateSetBacksLabelsPosition(camera,halfWidth,halfHeight,label,edge)
                });
            }

            requestAnimationFrame(animate);
            renderer.render(scene, camera);
            labelRenderer.render(scene, camera);
        };
        animate();
    }, [renderer, currentDesign]);
    
    useEffect(() => {
        // updateDrawingMode(DrawingModes.SEGMENT)

       mouseEventHandlers = new MouseEventHandlers(SceneManager, currentDrawingModeRef,obstacleTypeRef,
         updateDrawingMode,setPolygons,setObstacles,setDeleteConfirmationVisible,selectedIndices,setSelectedIndices,setPolygonDetails,
        modelMeshs,selectedPanels);

        const mousedownHandler = mouseEventHandlers.handleMouseDown.bind(mouseEventHandlers);
        const mousemoveHandler = mouseEventHandlers.handleMouseMove.bind(mouseEventHandlers);
        const mouseupHandler = mouseEventHandlers.handleMouseUp.bind(mouseEventHandlers);
        const resizeHandler = SceneManager.handleResize.bind(SceneManager);

        window.addEventListener('mousedown', mousedownHandler);
        window.addEventListener('mousemove', mousemoveHandler);
        window.addEventListener('mouseup', mouseupHandler);
        window.addEventListener("resize", resizeHandler);

        // Remove event listeners using the same direct references
        return () => {
            window.removeEventListener('mousedown', mousedownHandler);
            window.removeEventListener('mousemove', mousemoveHandler);
            window.removeEventListener('mouseup', mouseupHandler);
            window.removeEventListener("resize", resizeHandler);
        };
    }, [currentDrawingModeRef.current]);

    useEffect(() => {
        window.captureScreenshot = captureScreenshot;
        return () => {
            window.captureScreenshot = null;
        };
    }, [captureScreenshot]);

    const handleInput = (event, edgeIndex) => {
        if (event.key === 'Enter') {
            let inputValue =  parseFloat(event.target.value);

            updateSetbackUI(selectedIndices.segmentIndex,setInputValues,inputValue,edgeIndex);
            updateAvaArea(selectedIndices.segmentIndex,setAvailableArea)
        }
    };

    const handleChange = (e, index) => {
        const { value } = e.target;
        setInputValues(prevValues => ({
            ...prevValues,
            [index]: value
        }));
    };

    const handleBlur = (index) => {
        setInputValues(prevValues => ({
            ...prevValues,
            [index]: POLYGONS[selectedIndices.segmentIndex].setBacks.edgesData[index].value
        }));
    };

    return (
        <>
            {selectedIndices.segmentIndex !== null && editSetback && (
                POLYGONS[selectedIndices.segmentIndex].setBacks.edgesData.map((edge,index) => (
                    <div key={`${index}`} id={`setback-${index}`} className="input-container">
                        <input
                            type="number"
                            className="custom-input"
                            // TODO: remove neg values
                            value={inputValues[index] || edge.value}
                            onChange={(e) => handleChange(e, index)}
                            onKeyPress={(e) => handleInput(e, index)}
                            onBlur={() => handleBlur(index)}
                        />
                        <span className="unit-label">in</span>
                    </div>
                ))
            )}


            <div id={'canvas'} style={{height: "calc(100vh - 82.6px)"}} ref={refContainer}></div>
        </>
    )


}