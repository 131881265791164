import {DrawingGlobals, LABELS} from "../Global/variables";
import {BufferGeometry, LineSegments} from 'three';
import {LINES} from "../Global/materials";
import {removeLineMeshes} from "../Global/functions";

class RulerManager {
    constructor(scene, stopDrawing) {
        this.scene = scene;
        this.isRulerStarted = false;
        this.stopDrawing = stopDrawing;
    }

    handleMouseDown(point) {
        if (!this.isRulerStarted) {
            this.isRulerStarted = true;
            this.setRuler(point);
        } else {
            this.removeRuler();
            this.stopDrawing();
        }
    }

    handleMouseMove(point) {
        if(this.isRulerStarted){
            this.updateRuler(point);
            this.addMeasurementLabel(point);
        }
    }

    setRuler(point) {
        DrawingGlobals.intersectionPoints.push(point);
        let geometry = new BufferGeometry().setFromPoints([
            DrawingGlobals.intersectionPoints.slice(-1)[0],
            DrawingGlobals.intersectionPoints.slice(-1)[0],
        ]);
        let line = new LineSegments(geometry, LINES.drawingLineMaterial);
        line.frustumCulled = false;
        DrawingGlobals.lineMeshes.push(line);
        this.scene.add(DrawingGlobals.lineMeshes.slice(-1)[0]);
    }

    removeRuler() {
        LABELS.angle.visible = false;
        if(DrawingGlobals.intersectionPoints.length>0){
            removeLineMeshes(this.scene)
            DrawingGlobals.intersectionPoints.length = 0;
            DrawingGlobals.lineMeshes.length = 0;
        }

    }

    updateRuler(point) {
        DrawingGlobals.lineMeshes.slice(
            -1
        )[0].geometry.attributes.position.array[3] =
            point.x;
        DrawingGlobals.lineMeshes.slice(
            -1
        )[0].geometry.attributes.position.array[4] =
            point.y;
        DrawingGlobals.lineMeshes.slice(
            -1
        )[0].geometry.attributes.position.array[5] =
            point.z;
        DrawingGlobals.lineMeshes.slice(
            -1
        )[0].geometry.attributes.position.needsUpdate = true;
    }

    addMeasurementLabel(end) {
        let start = DrawingGlobals.intersectionPoints[0];
        LABELS.angle.visible = true;
        let dist = start.distanceTo(end) * 3.281;
        LABELS.angle.element.innerText = dist.toFixed(2) + " ft";
        LABELS.angle.position.lerpVectors(start, end, 0.5);
    }
}


export default RulerManager;