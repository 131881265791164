import * as THREE from 'three'
import {POLYGONS} from "../Global/variables";

export function addExtrude(points, polyShape,height,color, scene) {
    let extrudeSettings = {
        bevelEnabled: false,
    };

    let extrudeGeometry = new THREE.ExtrudeGeometry(polyShape, extrudeSettings);
    const tolerance = 0.0001;
    for(let c=0; c<extrudeGeometry.attributes.position.array.length;c+=3){
        let x = extrudeGeometry.attributes.position.array[c]
        let y = extrudeGeometry.attributes.position.array[c+1]
        let z = extrudeGeometry.attributes.position.array[c+2]
        for (let cord in points){
            if(Math.abs(x - points[cord].x) < tolerance && Math.abs(y - points[cord].y) < tolerance){
                if(z===0){
                    extrudeGeometry.attributes.position.array[c+2] = points[cord].z
                }
                else if(z===1){
                    extrudeGeometry.attributes.position.array[c+2] = points[cord].z+height;
                }
            }
        }
    }

   extrudeGeometry.attributes.position.needsUpdate =true;
    let wireframeGeometry = new THREE.EdgesGeometry(extrudeGeometry);
    let wireframeMaterial = new THREE.LineBasicMaterial({ color:color});
    let wireframeMesh = new THREE.LineSegments(wireframeGeometry, wireframeMaterial);

    wireframeMesh.material.transparent = true;
    wireframeMesh.material.opacity = 1;
    wireframeMesh.renderOrder =1;
    wireframeMesh.material.depthTest = false;

    scene.add(wireframeMesh);

    return wireframeMesh;
}
export function changeObstacleHeight(segmentIndex, obstacleIndex, height) {
    let obstruction = POLYGONS[segmentIndex].obstructions[obstacleIndex];

    let coordinates = POLYGONS[segmentIndex].obstructions[obstacleIndex].cords;
    let extrudeSettings={  bevelEnabled: false }
    let extrudeGeo = new THREE.ExtrudeGeometry(obstruction.polyShape, extrudeSettings );

    const tolerance = 0.0001;
    for(let c=0; c<extrudeGeo.attributes.position.array.length;c+=3){
        let x = extrudeGeo.attributes.position.array[c]
        let y = extrudeGeo.attributes.position.array[c+1]
        let z = extrudeGeo.attributes.position.array[c+2]
        for (let cord in coordinates){
            if(Math.abs(x - coordinates[cord].x) < tolerance && Math.abs(y - coordinates[cord].y) < tolerance){
                if(z==0){
                    extrudeGeo.attributes.position.array[c+2] = coordinates[cord].z
                }
                else if(z==1){
                    extrudeGeo.attributes.position.array[c+2] = coordinates[cord].z+height;
                }
            }
        }
    }
    let wireframeGeometry = new THREE.EdgesGeometry(extrudeGeo);


    obstruction.mesh.geometry.dispose();
    obstruction.mesh.geometry = wireframeGeometry;
    obstruction.mesh.material.transparent = true;
    obstruction.mesh.material.opacity = 1;
    obstruction.mesh.material.depthTest =false;
    obstruction.mesh.material.needsUpdate = true;
    obstruction.height = height;
    obstruction.mesh.geometry.attributes.position.needsUpdate = true;
}