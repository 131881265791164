import {point3d, TextHorizontalAlignment, TextVerticalAlignment} from "@tarikjabiri/dxf";
import {calculateLengthWidth, inch2feet} from "./DxfCalculations";

export function SegmentDetailsTable(label, data, tableStartX, tableStartY, cellHeight, cellWidths, drawing) {

    let obsColumnData = [];
    obsColumnData.push({index: 'POI', X: 'X', Y: 'Y'});
    for (let i = 1; i < data.length - 1; i++) {
        const element = data[i];
        element.y = (element.y * -1);
        obsColumnData.push({index: i, X: inch2feet(element.x), Y: inch2feet(element.y)});
    }

    let noOfRows = obsColumnData.length
    let totalColWidth = cellWidths.reduce((acc, width) => acc + width, 0);
    let headerText = `${label}-EDGES`

    addTableHeader(tableStartY, cellHeight, tableStartX, totalColWidth, cellWidths, drawing, headerText)
    drawColumns(tableStartX, tableStartY, cellWidths, cellHeight, noOfRows, drawing)
    drawRows(tableStartX, tableStartY, cellHeight, totalColWidth, noOfRows, drawing)
    addColumnData(obsColumnData, cellWidths, tableStartX, tableStartY, cellHeight, drawing)

    return totalColWidth + 12;
}

export function ObstacleDetailTable(label, data, tableStartX, tableStartY, cellHeight, cellWidths, drawing) {

    let obsColumnData = getObsPointsData(data)
    let totalColWidth = cellWidths.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
    let noOfRows = obsColumnData.length
    let headerText = `${label}-OBSTRUCTIONS`

    addTableHeader(tableStartY, cellHeight, tableStartX, totalColWidth, cellWidths, drawing, headerText)
    drawColumns(tableStartX, tableStartY, cellWidths, cellHeight, noOfRows, drawing)
    drawRows(tableStartX, tableStartY, cellHeight, totalColWidth, noOfRows, drawing)
    addColumnData(obsColumnData, cellWidths, tableStartX, tableStartY, cellHeight, drawing)

    return (((data[0].length + 2 + data[2].length) * 11) + 10)
}

function getObsPointsData(data) {
    let ObsData = [];
    let sno=0;
    ObsData.push({name: 'Obstructions', lengthRadius: 'Len/Dia', width: 'Width', X: 'X', Y: 'Y'});
    let dormerData = data[2];
    for (let index = 0; index < dormerData.length; index++) {
        let x = (dormerData[index][0].x).toFixed(2).toString();
        let y = (dormerData[index][0].y).toFixed(2).toString();
        let dimensions = calculateLengthWidth(dormerData[index]);
        ObsData.push({
            name: `P${sno}`,
            lengthRadius: dimensions.l,
            width: dimensions.w,
            X: inch2feet(x),
            Y: inch2feet(y)
        });
        sno++;

    }

    let obstructions = data[0];
    for (let index = 0; index < obstructions.length; index++) {
        let x = '';
        let y = '';
        if (data[1].length > 0) {
            x = (data[1][index].polygon[0].x).toFixed(2).toString();
            y = ((data[1][index].polygon[0].y)).toFixed(2).toString();
            let dimensions = calculateLengthWidth(data[1][index].polygon);
                ObsData.push({
                    name: `P${sno}`,
                    lengthRadius: dimensions.l,
                    width: dimensions.w,
                    X: inch2feet(x),
                    Y: inch2feet(y)
                });
            sno++;
        }
    }
    return ObsData;
}

export function TotalObsTable(data, tableStartX, tableStartY, cellHeight, cellWidths, drawing) {
    let obsColumnData = []
    let totalObs = data.length;
    let totalRecObs = data.filter(item => item.type === 'rectangle').length;
    let totalCirObs = data.filter(item => item.type === 'circle').length;
    obsColumnData.push({name: 'NAME', count: 'COUNT'});
    obsColumnData.push({name: 'Rectangle Obs', count: totalRecObs.toString()});
    obsColumnData.push({name: 'Circle Obs', count: totalCirObs.toString()});
    obsColumnData.push({name: 'Total Obstruction', count: totalObs.toString()});

    let totalColWidth = cellWidths.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
    let noOfRows = 4;

    let headerText = 'OBSTRUCTIONS-DETAILS'

    addTableHeader(tableStartY, cellHeight, tableStartX, totalColWidth, cellWidths, drawing, headerText)
    drawColumns(tableStartX, tableStartY, cellWidths, cellHeight, noOfRows, drawing)
    drawRows(tableStartX, tableStartY, cellHeight, totalColWidth, noOfRows, drawing)
    addColumnData(obsColumnData, cellWidths, tableStartX, tableStartY, cellHeight, drawing)

}

function addTableHeader(tableStartY, cellHeight, tableStartX, totalColWidth, cellWidth, drawing, headerText) {
    const headingRowY = tableStartY + cellHeight;
    let mid = point3d(tableStartX + totalColWidth / 2, headingRowY - cellHeight / 2, 0.6)
    drawing.addLine(point3d(tableStartX, headingRowY, 0), point3d(tableStartX + cellWidth.reduce((acc, width) => acc + width, 0), headingRowY, 0));
    drawing.addText(mid, 6, headerText, {
        colorNumber: 141,
        horizontalAlignment: TextHorizontalAlignment.Center,
        verticalAlignment: TextVerticalAlignment.Middle,
        secondAlignmentPoint: mid
    });
}

function drawColumns(tableStartX, tableStartY, cellWidth, cellHeight, noOfRows, drawing) {
    for (let i = 0; i <= 5; i++) {
        const x = tableStartX + cellWidth.slice(0, i).reduce((acc, width) => acc + width, 0);
        if (i === 5 || i === 0) {
            drawing.addLine(point3d(x, tableStartY + 11, 0), point3d(x, tableStartY - (noOfRows * cellHeight), 0));
        } else {
            drawing.addLine(point3d(x, tableStartY, 0), point3d(x, tableStartY - (noOfRows * cellHeight), 0));
        }
    }
}

function drawRows(tableStartX, tableStartY, cellHeight, cellWidth, noOfRows, drawing) {
    for (let i = 0; i <= noOfRows; i++) {
        const y = tableStartY - i * cellHeight;
        drawing.addLine(point3d(tableStartX, y, 0), point3d(tableStartX + cellWidth, y, 0));
    }
}

function addColumnData(data, cellWidths, tableStartX, tableStartY, cellHeight, drawing) {
    data.forEach((dataObj, index) => {
        const keyCellCenterX = cellWidths.map((width, i) => tableStartX + cellWidths.slice(0, i).reduce((acc, w) => acc + w, 0) + width / 2);
        const cellCenterY = tableStartY - index * cellHeight - cellHeight / 2;
        Object.entries(dataObj).forEach(([key, value], i) => {
            const mid = point3d(keyCellCenterX[i], cellCenterY, 0.6);
            drawing.addText(mid, 4.5, value, {
                colorNumber: i === 0 ? 141 : 50,
                horizontalAlignment: TextHorizontalAlignment.Center,
                verticalAlignment: TextVerticalAlignment.Middle,
                secondAlignmentPoint: mid,
            });
        });

    });
}