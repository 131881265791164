import { createBrowserRouter } from "react-router-dom";
import Designer from "../pages/Designer";
import GenerateDxf from "../pages/GenerateDxf";

const router = createBrowserRouter([
  { path: "/:token/:designID", element: <Designer/>},
  { path: "dxf/:token/:designID", element: <GenerateDxf/>},
]);


export default router;