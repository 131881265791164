import { Button } from "antd";
import Icon from "./Icon";

const IconButton = ({ src, className, onClick, ...restProps }) => {
  return (
    <Button
      size="small"
      type="text"
      className={className}

      icon={<Icon src={src} />}
      onClick={onClick}
      {...restProps}
    />
  );
};

export default IconButton;